var m = require("mithril")
var DatePicker = require('mithril-datepicker')
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
// var UrgencyDropdown = require("../components/UrgencyDropdown")
var Child = require("../models/Child")
var ChildObservation = require("../models/ChildObservation")


class ChildObservationCreate  {
    constructor(vnode) {
        if (vnode.attrs.Id) {
          this.child_id = vnode.attrs.Id;
          Child.getChild(this.child_id);
          const crumb = {url: '/child/' + vnode.attrs.Id+'/observationwrite',
                         text: "Observatie maken"}
          Breadcrumb.addCrumb(crumb);
        }
        this.date_from_picker = EvGService.format_date_to_datum(AppState.get_filter_date());
        this.date_to_picker = EvGService.format_date_to_datum(AppState.get_filter_date());

        this.view = this.view.bind(this);
        this.write_observation = this.write_observation.bind(this);
        this.check_dates = this.check_dates.bind(this);
    }

    check_dates() {
         let send_button = document.getElementById("send_observation");
         if(this.date_to_picker<this.date_from_picker) {
            send_button.disabled = true;
         } else {
            send_button.disabled = false;
         }
    }

    on_text_enter(event) {
      // console.log("// hide all other elements "+event);
      event.target.scrollIntoView();

      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          if(item.id==event.target.id) {
              item.rows = "10"; // geselecteerde textarea wat groter maken
          } else {
              item.style.display = 'none';
          }
      })

    }

    on_text_leave(event) {
      //console.log("// show all elements "+event);
      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          item.style.display = '';
          item.rows = "5" // geselecteerde textarea naar 5 regels
      })

    }

    write_observation(e) {
        // console.log("write_observation event: "+e);
        e.preventDefault();
        document.getElementById("send_observation").disabled = true;

        let formData = new FormData(document.getElementById("id_child_observation"));
        ChildObservation.writeChildObservation(formData);

        let parentCrumb = Breadcrumb.getParent();
        m.route.set(parentCrumb.url);
    }

    view(vnode) {
        var header_content = [
            m(Breadcrumb),
            m("span.filter-date", EvGService.filter_date),
        ]
        let child_selected = null;
        let other_child_select = null;
        let child = null;
        let child_full_name = '';
        if (Child.child) {
            // notitie  invoeren voor kind vanaf kind lijst
            child = Child.child;
            child_full_name = child.first_name+" "+child.last_name
            // terug gaat naar kind pagina
            // enige kind in de lijst
            child_selected = m("select",
                                  { id:"id_child", name:"child", required:"required", autofocus:"autofocus"},
                                   [ m("option",
                                     {value:child.id},
                                     child.first_name+" "+child.last_name),
                                   ]
            )
           // console.log("other_child_select Child.on_eiland_today_list: "+Child.on_eiland_today_list);
           other_child_select = m("select.evg_block.hide_on_text_enter",
                                 { id:"other_child", autofocus:"autofocus", multiple:"true"},
                                 [ // m("option", "Maak een Keuze"),
                                   Child.on_eiland_today_list.map(function(child){
                                       console.log(" other_child_select child On eiland today: "+child)
                                       if (Child.child.id != child.id) {
                                           return m("option",
                                                    {value:child.id},
                                                    child.first_name+" "+child.last_name)}
                                       }
                                   )
                                 ]
           )

        }
        else {
           // een optie invoeren voor een van de kinderen.
           // (kind keuze is nog niet gemaakt)
           child_selected = m("select.evg_block.hide_on_text_enter",
                                 { id:"child", required:"required", autofocus:"autofocus"},
                                 [ m("option", "Maak een Keuze"),
                                   Child.on_eiland_today_list.map(function(child){
                                       return m("option",
                                                {value:child.id},
                                                child.first_name+" "+child.last_name)}
                                   )
                                 ]
           )
        }

        const from_picker = m(DatePicker,
                         { date: this.date_from_picker,
                           onchange: (chosenDate) => {
                                     this.date_from_picker = EvGService.format_date_to_datum(chosenDate);
                                     this.check_dates();
                              }
                         });

        let childobservation_form = [
                 m("form", {id:"id_child_observation", onsubmit:this.write_observation } ,
                   [
                    m('input', {type:"hidden", name:"crew", value:"1"}), // todo: fix dit: Wordt in de API gezet op de ingelogde gebruiker. Id moet wel bestaan
                    m("textarea.evg_block.hide_on_text_enter",
                      {rows:"6", cols:"40", id:"id_text", name:"text", required:"required",
                       onfocusin:this.on_text_enter, onfocusout:this.on_text_leave,
                       placeholder:"Observatie Text.\nAlleen bedoeld voor (Leerdoel) observaties over een kind.\nNIET voor communicatie naar crew. Gebruik daarvoor 'Notitie maken'\nNIET voor communicatie naar ouders. Gebruik daarvoor 'Blogbericht maken' via de Kind pagina"}),

                    m("div.evg_block.hide_on_text_enter",
                          [m("label", "Kind"),
                           m(".input.value", child_selected)
                           ]),

                    //m( "div.evg_block.hide_on_text_enter",
                    //    'Hier Selectie Voor Andere Kinderen Meenemen'),

                    //m("div.evg_block.hide_on_text_enter",
                    //      [m("label", "Extra Kinderen"),
                    //       m(".input.value", other_child_select)
                    // //       m( "span", 'Hier Selectie Voor Andere Kinderen Meenemen')
                    //       ]
                    // ),

                    m( "div.evg_block.hide_on_text_enter",
                        [ m("label", "Datum"),
                          m('input', {id:"id_date_from", type:"hidden", name:"date", value:this.date_from_picker }),
                          m('.input.value', from_picker)
                        ]
                      ),


                    m("button.evg_action.hide_on_text_enter",
                      {id:"send_observation", type:"submit"},
                      "Observatie voor Kind aanmaken"),
                   ])

        ];

        m.render(document.getElementById("header"),
                 header_content)
        return m( "childobservation.evg_module", childobservation_form )
    }
}


module.exports = ChildObservationCreate
