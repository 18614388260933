var m = require("mithril")


const smallDZ = m('.sdz',":00");


class TimeAsBarLabels {
    constructor(vnode) {
        this.start_time = vnode.attrs.StartTime;
        this.end_time = vnode.attrs.EndTime;

        this.day_start_time = vnode.attrs.DayStartTime ? vnode.attrs.DayStartTime : 7;
        this.day_end_time = vnode.attrs.DayEndTime ? vnode.attrs.DayEndTime : 18;
        this.total_width = vnode.attrs.BarVW ? vnode.attrs.BarVW : 65;  // 50vw    1900 - 700 = 1200
        this.one_hour_width = this.total_width / (this.day_end_time - this.day_start_time);
    }

	view(vnode) {
	   let time_label_data = [];

       // als we 18:00 meegeven, wrdt geprint t/m 17:00. daarom <
	   for(let time = this.day_start_time; time < this.day_end_time; time++) {
	       time_label_data.push(m('.time-cell.time-label', {style: 'width: '+this.one_hour_width+'vw'},
	                              time+":00"));
	       // time_label_data.push(m('.time-cell.time-label', time));
	   }

	   return m('.starttime-endtime-labels', {style: 'width: '+this.total_width+'vw'},
	            time_label_data)
    }

}




module.exports = TimeAsBarLabels
