var m = require("mithril")
var VersionCheck = require("../services/VersionCheck") // mag wel weggerefactord
/* Alle data van EVG eerst maar eens in een enkele service */
var AppState = require("./AppState")
var Auth = require("./Auth")
var Settings = require("../models/Settings");

var School = require("../models/School")
var Child = require("../models/Child")
var DayType = require("../models/DayType")
var OptionValueMap = require("../models/OptionValueMap")

var ChildEilandday = require("../models/ChildEilandday")
var CrewEilandday = require("../models/CrewEilandday")
var ActivityResource = require("../models/ActivityResource")
var ChildCrewRatio = require("../models/ChildCrewRatio");
var ChildNote = require("../models/ChildNote");
var ChildObservation = require("../models/ChildObservation");
var MentorChild = require("../models/MentorChild")
var DaytypeNote = require("../models/DaytypeNote")
var Schoollogistic = require("../models/Schoollogistic")
var CaretakerChangeRequest = require("../models/CaretakerChangeRequest")


// data voor de Ouder
var LastMinuteOffer = require("../models/LastMinuteOffer")
var LastMinuteClaim = require("../models/LastMinuteClaim")

// export const 12_uur=12*60*60*1000;

class EvGService  {

    constructor() {
        // console.log("EvGService.constructor");

        // Moet Naar Settings/SettingsPage
        this.urgency_option_list = [{name:"Geen",   value:0},
                             {name:"Laag",   value:2},
                             {name:"Middel", value:4},
                             {name:"Hoog",   value:6}];

        this.option_start_time = 0; // Aankomst
        this.option_first_name = 1; // Voornaam
        this.option_age = 2; // leeftijd, op waarde date_of_birth

        this.child_order_option_list = [[this.option_start_time, "Aankomst op EvG"],
                                 [this.option_first_name, "Voornaam"],
                                 [this.option_age, "Leeftijd"]];
        // Moet Naar Settings/SettingsPage


        this.load_server_data = this.load_server_data.bind(this);
        this.load_date_related_data = this.load_date_related_data.bind(this);
        // this.format_date_to_datum = this.format_date_to_datum.bind(this);
    }

    load_server_data() {
        if(Auth.isLoggedIn()){
            DayType.loadDaytypeList();
            School.loadSchoolList();
            // Settings.loadList();
            VersionCheck.check_version_on_server();
        } else {
            if(device) {
                console.info("device.platform: "+device.platform)
                console.info("device.uuid: "+device.uuid)
            } else {
                console.error("cordova/device not found");
            }
            console.info("load_server_data niet uitgevoerd want niet ingelogd");
        }
     }

    load_date_related_data() {
        if(Auth.isLoggedIn()){
            Child.loadChildrenToday()
            ChildEilandday.loadOnEilandByDate(); // loadList
            CrewEilandday.loadCrewEilandTodayList(); // loadList
            ActivityResource.loadFromServer(); // loadList
            ChildCrewRatio.loadChildCrewRatio(); // loadList
            MentorChild.loadMentorChildList(); //loadList
            ChildNote.loadList();
            ChildObservation.loadList();
            DaytypeNote.loadList();
            Schoollogistic.loadList();
            OptionValueMap.loadList();

            // niet datum gerelateerd, maar toch wel laden op datum-wissel
            LastMinuteOffer.loadLastMinuteOfferList();
            LastMinuteClaim.loadLastMinuteClaimList();
            CaretakerChangeRequest.loadChangeRequestList();
        }
    }

// Naar DateTimeUtils.js
// START

    format_date_to_datum(date) {
        let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
// END



}

module.exports = new EvGService()
