var m = require("mithril")
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth") // hier weg, naar models/DaytypeNote
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var MyChildDropdown = require("../components/MyChildDropdown")
var LastMinuteClaim = require("../models/LastMinuteClaim")

class LastMinuteClaimPage  {

    constructor(vnode) {
        this.lastminuteclaim_id = vnode.attrs.Id;
        this.lastminuteclaim = LastMinuteClaim.fetchLastMinuteClaimById(this.lastminuteclaim_id);
        const crumb = {url:"/lastminuteclaim/"+this.lastminuteclaim_id,
                       text:"Geclaimed Nippertje"};
        Breadcrumb.addCrumb(crumb);

        this.view = this.view.bind(this);
        // this._claim_lastminute = this._claim_lastminute.bind(this);
    }

    //_claim_lastminute() {
    //    document.getElementById("id_button_claim_lastminute").disabled = true;
    //    let formData = new FormData(document.getElementById("id_claim_lastminute"));

     //   LastMinuteOfferM.claimLastMinuteOffer(this.lastminute_id, formData);
    //}

    view(vnode) {
        // var my_this = this; //needed for the onchange
        var _lm_date = "-";
        var _from_to = "no-from-to";
        //if(this.lastminuteclaim) {
        //    _lm_date = this.lastminuteoffer.date;
        //    _from_to = this.lastminuteoffer.start_time+" - "+this.lastminuteoffer.end_time;
        //}

        /** let lastminuteclaim_form = [
          m("form", {id:"id_claim_lastminute", onsubmit:"" },
            [ m("div.evg_block",
                  [m("label", "Datum"),
                   m('div.data', _lm_date)]),
              m("div.evg_block",
                  [m("label", "Van-Tot"),
                   m('div.data', _from_to )]),

              m( "div.evg_block",
                  [m("label", "Kind"),
                   m(MyChildDropdown)
                  ]),
              m( "div.evg_block",
                  [m("label", "Begeleidend bericht"),
                     m("textarea.input",
                       {rows:"4", cols:"40",
                        id:"id_request", name:"request",
                        style:"width: inherit;",
                        placeholder:"E.vt. exta vragen."})
                  ]),

              m("button.evg_action",
                  {id:"id_button_claim_lastminute", type:"button",
                   disabled: (AppState.getChildFilter()>0 ? false : true),
                   onclick:this._claim_lastminute},
                  "Nippertje Claimen"),

            ])
        ] **/

        m.render(document.getElementById("header"),
                [m(Breadcrumb),
                 m("span.filter-date", AppState.get_filter_date()),
                ]);

        return m("lastminuteclaim-page.evg_module", "Login op de Desktop/Browser. Het werkt hier nog niet." )
    }
}


module.exports = LastMinuteClaimPage
