var m = require("mithril")
var DatePicker = require('mithril-datepicker')
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth") // hier weg, naar models/DaytypeNote
var AppState = require("../services/AppState")
var DatePicker = require('mithril-datepicker')
// import {DaytypeDropdown, ChildRelatedDaytypeDropdown } from "../components/DaytypeDropdown";
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var UrgencyDropdown = require("../components/UrgencyDropdown")

var Breadcrumb = require("../components/Breadcrumb")
var DaytypeNote = require("../models/DaytypeNote")


class DaytypeNoteCreate  {

    constructor(vnode) {
        const crumb = {url:"/daytypenote/"+vnode.attrs.Date+"/"+vnode.attrs.Daytype+"/create",
                      text:"Aanmaken"};
        Breadcrumb.addCrumb(crumb);

        this.date_from_picker = EvGService.format_date_to_datum(AppState.get_filter_date());
        this.date_to_picker = EvGService.format_date_to_datum(AppState.get_filter_date());
        this.day_type = AppState.filter_daytype;
        if (this.day_type == 0) {
                this.day_type = 6; // Als geen dagtype in het filter geselecteerd: 6 = algemeen
        }

        this.view = this.view.bind(this);
        this.validate_write_note = this.validate_write_note.bind(this);
        this.write_note = this.write_note.bind(this);
        this.on_text_leave = this.on_text_leave.bind(this);
        this.on_text_enter = this.on_text_enter.bind(this);
        this.validate_dates = this.validate_dates.bind(this);
        this.validate_daytype = this.validate_daytype.bind(this);
    }

    validate_dates() {
         console.log("check_dates ?"+this.date_to_picker+"<"+this.date_from_picker);
         var send_button = document.getElementById("send_note");
         if(this.date_to_picker<this.date_from_picker) {
            console.log("TO ligt voor  FROM")
            send_button.disabled = true;
            return false
         }
         send_button.disabled = false;
         return true
    }
    validate_daytype() {
        // console.log("check_AppState.filter_daytype?"+AppState.filter_daytype );
        if(AppState.filter_daytype>0) {
           return true
        }
        return false
    }

    validate_write_note() {
       //if( validate_dates() // &&
           // check_daytype_selected()
       //    ) {
       //  document.getElementById("send_note").disabled = true;
         write_note();
       //}
    }

    write_note(e) {
        console.log("MOVE TO MODELS DaytypeNote write_note event: "+e);
        e.preventDefault();
        document.getElementById("send_note").disabled = true;
        var formData = new FormData(document.getElementById("id_daytype_note"));
        DaytypeNote.write_daytype_note( formData );
        m.route.set("/daytypenotelist");
    }

    on_text_enter(event) {
      // console.log("// hide all other elements "+event);
      event.target.scrollIntoView();

      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          if(item.id==event.target.id) {
              item.rows = "10"; // geselecteerde textarea wat groter maken
          } else {
              item.style.display = 'none';
          }
      })

    }

    on_text_leave(event) {
      //console.log("// show all elements "+event);
      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          item.style.display = '';
          item.rows = "5" // geselecteerde textarea naar 3 regels
      })

    }

    view(vnode) {
       m.render(document.getElementById("header"),
                [m(Breadcrumb),
                 m("span.filter-date", AppState.get_filter_date()),
                ]);

        var from_picker = m(DatePicker,
                         { date: this.date_from_picker,
                           onchange: (chosenDate) =>{
                                     this.date_from_picker = EvGService.format_date_to_datum(chosenDate);
                                     this.validate_dates();
                              }
                         });

        var to_picker =  m(DatePicker,
                         { date: this.date_to_picker,
                           onchange: (chosenDate) => {
                                     this.date_to_picker = EvGService.format_date_to_datum(chosenDate);
                                     this.validate_dates();
                              }
                         });

       let daytypenotewrite_form = [
          m("form", {id:"id_daytype_note", onsubmit:this.write_note },
            [ m("textarea.evg_block.input.hide_on_text_enter",
                   {id:"id_content", name:"content",
                    rows:"4", cols:"40", required:"required",
                    onfocusin:this.on_text_enter, onfocusout:this.on_text_leave,
                    placeholder:"Notitie Bericht voor Groep."}),

              m( ".evg_block.hide_on_text_enter",
                  [m("label", "Groep"),
                   m(".input", m(DaytypeDropdown, {dropdownType: "select"}) )
                  ]),
              m( ".evg_block.hide_on_text_enter",
                  [m('input', {id:"id_date_from", type:"hidden", name:"date", value:this.date_from_picker }),
                   m("label", "Van"),
                   m('.input', from_picker)
                  ]),
              m( ".evg_block.hide_on_text_enter",
                  [m('input', {id:"id_date_to", type:"hidden", name:"date_to", value:this.date_to_picker }),
                   m("label", "T/m"),
                   m('.input', to_picker)
                  ]),
              m(".evg_block.hide_on_text_enter",
                  [m("label", "Urgentie"),
                   m(".input", m(UrgencyDropdown))
                  ]),

              m("button.evg_action.hide_on_text_enter",
                  {id:"send_note", type:"submit"}, //, onclick:this.write_note},
                  "Notitie voor Dagsoort aanmaken"),

            ])
       ]


        return m("daytypenotecreate.evg_module", daytypenotewrite_form )
    }
}

module.exports = DaytypeNoteCreate
