var m = require("mithril")
var AppState = require("../services/AppState")
var Auth = require("../services/Auth")
var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")
var Child = require("../models/Child")

var BlogMsg = require("../models/BlogMsg")

class MsgList {

    view(vnode) {
        var msg_list = []
        BlogMsg.child_blogmsg_list.map( function(blogmsg) {
                if(blogmsg.content || blogmsg.image_thumb) {
                    var blog_content = [];
                    blog_content.push(m("span.pub_date", blogmsg.publication_date.substring(0, 10)))
                    if(blogmsg.content) {
                       blog_content.push(m.trust(blogmsg.content));
                    }
                    if(blogmsg.image_thumb) {
                       var figure_content = []
                       var img_url = Auth.getDomain()+blogmsg.image_thumb;
                       figure_content.push(m('img', {'src':img_url}));
                       if(blogmsg.image_caption) {
                           figure_content.push(m('figcaption', m.trust(blogmsg.image_caption)));
                       }
                       blog_content.push(m('figure', figure_content));
                    }
                    msg_list.push(m('li.evg-list-item',
                                      m('span.blog-entry',
                                         blog_content)
                                   ));
                }
         });
        if(msg_list.length==0) {
           msg_list.push(m('li.evg-list-item', "Geen Blog Berichten"));
        }
        return m('ul.evg-list.list_level_1', msg_list);
    }
}



class BlogMsgList {

    constructor(vnode) {
        // console.log("BlogMsgList child: "+vnode.attrs.Id);
        BlogMsg.loadBlogMsgListForChild(vnode.attrs.Id);
        const crumb = {url: '/child/' + vnode.attrs.Id+'/blogmsglist',
                       text: "Blogberichten"};
        Breadcrumb.addCrumb(crumb);

        this.header_data = [m(Breadcrumb),
                    m("span.filter-date", AppState.get_filter_date())]

    }

    view(vnode) {
        var child = Child.child;
        if(child==null) {
            m.route.set("/splash");
        }

        m.render(document.getElementById("header"), this.header_data);

        return m("childnotelist.evg_module",
                 [m("div", "Blogberichten van "+child.first_name+" "+child.last_name),
                  m("div.evg_list_container_no_filter", m(MsgList)),
                 ])
    }
}

module.exports = BlogMsgList
