var m = require("mithril")
var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var ChildEilandday = require("../models/ChildEilandday")
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var DaytypeChildCrewRatio = require("../components/DaytypeChildCrewRatio")
var ChildEilanddayActivity = require("../components/ChildEilanddayActivityResource")
var Breadcrumb = require("../components/Breadcrumb")
var ChildImgName = require("../components/ChildImgName")
var ChildEilanddayListItem = require("../components/ChildEilanddayListItem")
var CrewScheduleList = require("../views/CrewScheduleList");


class ChildEilanddayList {
    constructor(vnode) {
      this.displayed_items = [];
    }

    // todo: map eruit, filter  erin.
    filter(childeilandday){
         if (AppState.daytypeFilter == 0 || childeilandday.day_type == 6 ||
             childeilandday.day_type == AppState.daytypeFilter) {
             return true
         }
    }

	view(vnode) {
        if (ChildEilandday.status == 'Ophalen Data') {
            return m('span.evg_message', ChildEilandday.status);
        }
        if (ChildEilandday.childeiland_today_count == 0) {
            return m('span.evg_message', "Geen Kinderen vandaag");
        }

		let _displayed_items = [];
		ChildEilandday.childeiland_today_list.forEach((childeilandday) => {
		    // item tonen als 'alles tonen' is geselecteerd (0),
		    // of als het dagtype gelijk is aan het geselecteerde dagtype.
		    // Algemeen(6) moet ook altijd getoond worden
            if (AppState.daytypeFilter == 0 || // alles tonen
                childeilandday.day_type == 6 || // Algemeen
                childeilandday.day_type == AppState.daytypeFilter) {
                    _displayed_items.push( m(ChildEilanddayListItem, { childeilandday: childeilandday,}) );
                }
		});

		if (_displayed_items.length == 0) {
            return m('span.evg_message', "Geen Kinderen. "+ChildEilandday.status);
		} else {
		    // Een extra item toevoegen om expliciet aa te geven dat een lijst is geeindigd
		    _displayed_items.push(m('li.evg-list-item.end-of-list-item',
		                            m('span',
		                              [ m('span.float-left',"Einde lijst. "),
		                                m('span.float-right',"Kind niet in lijst? Of juist Kind teveel? Even Melden! ")]
		                                )))
		}
		return m('ul.evg-list', _displayed_items);
	}
}


class ChildScheduleList {
    constructor(vnode) {
          const crumb = {url:'/childschedulelist',
                         text:'Groep Rooster'};
          Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("schedule.evg_module",
                       [m( "div.evg_block",
                          [m("label", "Groep"), m(".value.input", m(DaytypeDropdown))]),
                        m( "div.evg_block",
                           [ m(DaytypeChildCrewRatio) ,m(CrewScheduleList.CrewScheduleListShowHide)] ),
                        m( "div.evg_list_container_one_filter", m(ChildEilanddayList)),
                       ]
                      )
    }

}


module.exports = ChildScheduleList
