var m = require("mithril")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var Child = require("../models/Child");

var ChildNote = require("../models/ChildNote");


class NoteListItem  {

    constructor(vnode) {
        this.indicate_have_seen_note = this.indicate_have_seen_note.bind(this);
        this.renderHaveSeenNote = this.renderHaveSeenNote.bind(this);
        this.view = this.view.bind(this);
    }

    indicate_have_seen_note() {
        if(this.note.urgency > 3) {
            if( this.note.seen_once == true) {
               ChildNote.have_seen_note(this.note.id);
               this.note.marked_as_seen = true; // tijdelijke test
            } else {
               this.note.seen_once = true;
               m.redraw();
            }
        } else {
               ChildNote.have_seen_note(this.note.id);
               this.note.marked_as_seen = true; // tijdelijke test
               m.redraw();
        }
    }

    renderHaveSeenNote() {
       if( this.note.marked_as_seen == true) return; // tijdelijke test
       if(this.note.urgency > 3 ) {
           if(this.note.seen_once == true) {
              return m(".have_seen", "TAP NOGMAALS voor aangeven Gezien");
           }
           return m(".have_seen", "TAP 2x voor aangeven Gezien");
       }
       return m(".have_seen", "TAP voor aangeven Gezien");
    }

    view(vnode){
        this.note = vnode.attrs.note;
        const urgency_class = ".urgency_"+this.note.urgency;
        let note_period = this.note.date;
        if (this.note.date_to) {
            if (this.note.date == this.note.date_to) {
                note_period = note_period+".";
            } else {
                note_period = note_period+" t/m "+this.note.date_to;
            }
        } else {
            note_period = "Vanaf "+note_period; // + " - vandaag";
        }

        return m('li.evg-list-item.urgency'+urgency_class,
                 {onclick:this.indicate_have_seen_note, id:"child_"+this.note.child_id},
                 [ m('.urgency', this.note.child_full_name+' '+note_period),
                   this.renderHaveSeenNote(),
                   m('.urgency', this.note.content)
                 ]
                 );
    }
}


class NoteList {

    constructor(vnode) {
        this.filter_note = this.filter_note.bind(this);
        this.test_child_urgency = this.test_child_urgency.bind(this);
    }

    test_child_urgency(note) {
          if (AppState.childFilter==0 && AppState.urgencyFilter==0 ) {
              return true
          }

          if (AppState.childFilter>0 && AppState.urgencyFilter==0 ) {
               if( AppState.childFilter==note.child_id  ) {
                  return true
               }
               return false
          }
          if (AppState.childFilter==0 && AppState.urgencyFilter>0 ) {
               if( AppState.urgencyFilter<=note.urgency ) {
                  return true
               }
               return false
          }

          if (AppState.childFilter>0 && AppState.urgencyFilter>0 ) {
               if( AppState.childFilter==note.child_id  && AppState.urgencyFilter<=note.urgency ) {
                  return true
               }
               return false
          }

          throw "Fail in TestChildUrgency";
    }

    filter_note(note) {
        if ( AppState.overrideHasSeen ) {
            if (this.test_child_urgency(note)) {
                  return true
               }
            // no filters set, override all HasSeen notes
            return false
        } else {
            // now test for non-overridden state
            if (note.marked_as_seen) {
               return false
            }

            if (this.test_child_urgency(note)) {
                  return true
             }
            return false
        }
        throw "Fail To Filter in ChildrenNoteList";
    }

    view(vnode) {
        let child_id = vnode.attrs.child_id;
        // let filtered_note_list = ChildNote.list.filter( this.filter_note );
        let filtered_note_list = ChildNote.getListForChild(child_id).filter( this.filter_note );

        let m_note_list = [];
        if(filtered_note_list.length==0) {
           m_note_list.push(m('li.evg-list-item', "Geen Notities"));
        } else {
           filtered_note_list.forEach( (f_note) => {
              m_note_list.push(m(NoteListItem, {'note': f_note}));
           })
           m_note_list.push(m('li.evg-list-item.end-of-list-item',
                            m("span", "Einde lijst")));
        }
        return m('ul.evg-list.list_level_1', m_note_list)
    }
}


class ChildNoteList {

    constructor(vnode) {
        // console.log("ChildNoteList child: "+vnode.attrs.Id)
        const crumb = {url: '/child/' + vnode.attrs.Id+'/notelist',
                       text: "Notities"};
        Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              if(Child.child==null) {
                  m.route.set("/splash");
              }

              let child_full_name = Child.child.first_name+" "+Child.child.last_name;
              return m("childnotelist.evg_module",
                       [m(".label", "Notities over: "+child_full_name),
                        m(".evg_list_container_one_filter",  // geen filter, maar bovenstaande div neem ook ruimte in
                          m(NoteList, {child_id:Child.child.id})),
                       ]
                      )
    }
}

module.exports = ChildNoteList
