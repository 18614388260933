var m = require("mithril")
var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")

class Splash {
    constructor(vnode) {
        const splash_crumbs =[ {url: '/menu', text: "Menu"},
                               {url: '/splash', text: "SplaT"}
                             ]
        Breadcrumb.setCrumbs(splash_crumbs);
    }

    view(vnode) {
        m.render(document.getElementById("header"),
                  [ m(Breadcrumb),
                  ]);
        return m( "splash.evg_module",
                 [ // m("p", "Versie Update @ "+EvGService.delta_version_refresh),
                   //m("p", "Gebruik het EILAND-logo als 'terug-knop'"),
                   //m("p", "Klik op @Keesje voor help op een pagina")
                   m("p", "Hier komt !!!SPLAT!!!informatie die per dag bekeken moet worden")
                 ])
    }
}

module.exports = Splash
