var m = require("mithril")
// Naar DateTimeUtils.js
// START
// function  _minutesToDecimalFractionHour(min) {
function  _minutesToFraction(min) {
    switch(min) {
        case 0: return 0;
        case 15: return 0.25;
        case 30: return 0.5;
        case 45: return 0.75;
        default: throw "Alleen 0, 15, 30 of 45 minuten. Niet "+min+"."
    }
}

function  _fractionToMinutes(frac) {
    switch(frac) {
        case 0: return "00";
        case 0.25: return "15";
        case 0.5: return "30";
        case 0.75: return "45";
        default: throw "Alleen 0, 0.25, 0.5 of 0.75 minuten. Niet "+frac+"."
    }
}

function _stringToTimeDecimal(time_str){
     let a = time_str.split(":");
     let time = parseInt(a[0]); // - this.day_start_time;
     if(parseInt(a[1])>0) { time += _minutesToFraction(parseInt(a[1])) };
     return time
}

// time naar string met eventuele voorloop 0
function _timeDecimalToString( time_decimal ) {
   let time_string = (time_decimal - (time_decimal % 1))+":"+_fractionToMinutes(time_decimal % 1)
   if (time_decimal<10) {
      return "0"+time_string
   }
   return time_string
}
// END


class TimeAsBar {

    constructor(vnode) {
        this.time_list = vnode?.attrs?.TimeList ? vnode.attrs.TimeList : [];
        this.addStartEndTime = this.addStartEndTime.bind(this);
        this.setTimeList = this.setTimeList.bind(this);
        this. calculateStartTimeEndTime = this. calculateStartTimeEndTime.bind(this);

        // begin en eind van de hele dag
        this.day_start_time = vnode?.attrs?.DayStartTime ? vnode.attrs.DayStartTime : 7;
        this.day_end_time = vnode?.attrs?.DayEndTime ? vnode.attrs.DayEndTime : 18;

        // vroegste begin en laatste einde van de dag
        this.start_time = this.day_end_time; // start tijd loopt naar voren
        this.end_time = this.day_start_time; // en eind_tijd naar achteren,

        this.total_width = vnode?.attrs?.BarVW ? vnode.attrs.BarVW : 65;  // 50vw    1900 - 700 = 1200
        this.one_hour_width = this.total_width / (this.day_end_time - this.day_start_time);

        // this.start_end_time_list = vnode.attrs.StartEndTimeList ? vnode.attrs.StartEndTimeList : [];
    }

    addStartEndTime(start_time, end_time){
        //console.log("TimeAsBar: ====== "+start_time+" ============ "+end_time+" ==============");
        let _timeItem = [start_time, end_time];
        this.time_list.push(_timeItem);
        //console.log("TimeAsBar: this.time_list.length: "+this.time_list.length);
        let _start_time = _stringToTimeDecimal(start_time);
        let _end_time = _stringToTimeDecimal(end_time);
        if(_start_time<this.start_time) {
           this.start_time = _start_time;
           this.start_time_text = start_time;
        }
        if(_end_time>this.end_time) {
           this.end_time = _end_time
           this.end_time_text = end_time;
        }
        //console.log("TimeAsBar: this.time_list.length: "+this.time_list.length);
        //console.log("====== "+this.start_time+" ============ "+this.end_time+" ==============");
    }
    setTimeList(time_list) {
        this.time_list = time_list;
        // haal de vroegste start_tijd time[0] en de laatste eind_tijd time[1]
        this.time_list.forEach((time_tuple)=> {
             let _start_time = _stringToTimeDecimal(time_tuple[0]);
             let _end_time = _stringToTimeDecimal(time_tuple[1]);
             if(_start_time<this.start_time) {this.start_time = _start_time}
             if(_end_time>this.end_time) {this.end_time = _end_time}
        });
        // console.log("TimeAsBar: this.time_list.length: "+this.time_list.length);
        // console.log("====== "+this.start_time+" ============ "+this.end_time+" ==============");
    }

    calculateStartTimeEndTime() {
        // this.time_list = time_list;
        // haal de vroegste start_tijd time[0] en de laatste eind_tijd time[1]
        // console.log("TimeAsBar: this.time_list.length: "+this.time_list.length);
        this.time_list.forEach((time_tuple)=> {
             let _start_time = _stringToTimeDecimal(time_tuple[0]);
             let _end_time = _stringToTimeDecimal(time_tuple[1]);
             if(_start_time<this.start_time) {this.start_time = _start_time}
             if(_end_time>this.end_time) {this.end_time = _end_time}
        });

        this.start_time_text = _timeDecimalToString(this.start_time);
        this.end_time_text = _timeDecimalToString(this.end_time);
        // console.log("====== "+this.start_time+" ============ "+this.end_time+" ==============");
        // console.log("====== "+this.start_time_text+" ============ "+this.end_time_text+" ==============");
    }

	view(vnode) {
	    // console.log("vnode.attrs.TimeList: "+vnode.attrs.TimeList);
	    // console.log("====== "+vnode.attrs.StartTime+" ============ "+vnode.attrs.EndTime+" ==============");
	    // let start_time = vnode.attrs.StartTime;
	    // todo; deze test moet overbodig gemaakt
	    if(vnode.attrs.StartTime && vnode.attrs.EndTime) {
            let start_time = _stringToTimeDecimal(vnode.attrs.StartTime) - this.day_start_time;
            // console.log("start_time: "+start_time);
            let end_time = this.day_end_time - _stringToTimeDecimal(vnode.attrs.EndTime);
            // console.log("end_time: "+end_time);

            let pre_width = this.one_hour_width * (start_time); // 100); // 0.05; // start_time-700
            let post_width = this.one_hour_width * (end_time); // 100); // 1900-end_time
            let start_end_width = this.total_width - pre_width - post_width;
            let time_data = [];
            time_data.push(m('.time-off', {style: 'width: '+pre_width+'vw'}) );
            time_data.push(m('.time-on', {style: 'width: '+start_end_width+'vw'}));
            time_data.push(m('.time-off', {style: 'width: '+post_width+'vw'}));
            time_data.push(m('.time-as-text', {style: 'width: '+this.total_width+'vw'},
                             vnode.attrs.StartTime+" - "+vnode.attrs.EndTime)); //+" : "+this.time_list.length));

            return m('.start_time_end_time_bar', {style: 'width: '+this.total_width+'vw'},
                     time_data)
	    }
        //if(this.time_list.length==0){
        //    throw "Lege tijdLijst mag (nog) niet"
        //}
        this.calculateStartTimeEndTime();
	    // console.log("TimeAsBar.view: ====== "+this.start_time+" ============ "+this.end_time+" ==============");

	    let time_step = this.day_start_time;
        let time_data = [];
        //this.time_list.forEach((time)=>{
        //    console.log("time [0]:"+time[0]+"   [1]:"+time[1]);
        //});

        let pre_width = this.one_hour_width * (this.start_time - this.day_start_time); // 100); // 0.05; // start_time-700
        let post_width = this.one_hour_width * ( this.day_end_time - this.end_time); // 100); // 1900-end_time
        let start_end_width = this.total_width - pre_width - post_width;

        time_data.push(m('.time-off', {style: 'width: '+pre_width+'vw'}) );
        time_data.push(m('.time-on', {style: 'width: '+start_end_width+'vw'}));
        time_data.push(m('.time-off', {style: 'width: '+post_width+'vw'}));
        time_data.push(m('.time-as-text', {style: 'width: '+this.total_width+'vw'},
                         this.start_time_text+" - "+this.end_time_text)); // +" : "+this.time_list.length));
        return m('.start_time_end_time_bar', {style: 'width: '+this.total_width+'vw'},
                  time_data)
    }

}

module.exports = TimeAsBar
