var m = require("mithril");
var EvGService = require("../services/EvGService");
// var c  = require("Connection"); Werkt niet

class NetworkStatus  {

    constructor() {
        this.checkConnection = this.checkConnection.bind(this);
        this.handleOnline = this.handleOnline.bind(this);
        this.handleOffline = this.handleOffline.bind(this);
        this.states = {};
        try{
           // Connection lijkt hier nog niet te bereiken
           console.log("Connection.UNKNOWN: "+Connection.UNKNOWN);
        } catch (e) {
           console.error(e);
        }

    }

    checkConnection() {
        let networkState = navigator.connection.type;
        console.log('Connection type: ' + networkState);
        if(this.states.length==0){
            this.states[window.Connection.UNKNOWN]  = 'Unknown connection';
            this.states[Connection.ETHERNET] = 'Ethernet connection';
            this.states[Connection.WIFI]     = 'WiFi connection';
            this.states[Connection.CELL_2G]  = 'Cell 2G connection';
            this.states[Connection.CELL_3G]  = 'Cell 3G connection';
            this.states[Connection.CELL_4G]  = 'Cell 4G connection';
            this.states[Connection.CELL]     = 'Cell generic connection';
            this.states[Connection.NONE]     = 'No network connection (Browser Default)';
            this.states['undefined']  = 'Fout bekomen  type connection';
        }
        console.log('Connection type: ' + this.states[networkState]);
    }

    handleOnline(){
        console.log("Er Is Netwerk Verbinding...");
        EvGService.load_server_data();
        EvGService.load_date_related_data();
        m.redraw();
    }

    handleOffline(){
        console.log("Geen Netwerk Verbinding...");
        m.redraw();
    }

}

module.exports = new NetworkStatus();
