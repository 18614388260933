var m = require("mithril")


class CrewEilanddayListItem {

    constructor(vnode) {
    }

	view(vnode) {
        const creweilandday = vnode.attrs.creweilandday;
        let item_data = [];

        if(vnode.attrs.withFullName) {
            item_data.push(m('.w2b-1.pull-left',
                             [m('.first-name', creweilandday.first_name),
                              m('.last-name', creweilandday.last_name)]
                              ))
        }

        let _dagsoort_role = m('.daytype', 'Niet beschikbaar');
        if ( creweilandday.day_type != 3 ) { // exclusief 'Niet beschikbaar'
          _dagsoort_role = [m('.daytype', creweilandday.day_type_name ),
                            m('.role', creweilandday.role_display)
                           ];
        }
        item_data.push( m('.daytype-role.pull-left',  _dagsoort_role))

        const _time_full = creweilandday.from_time_short+'-'+creweilandday.to_time_short;
        item_data.push(m('.pull-right', _time_full))

        const crew_item_route =  '#!/crew/'+creweilandday.crew + '/eilandday/'+creweilandday.day;
		return m('li.evg-list-item',
		         m('a',
		           { href: crew_item_route },
		           item_data))
	}
}

module.exports = CrewEilanddayListItem

//module.exports {
//   CrewEilanddayListItem
//}
