var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var CrewEilandday = require("../models/CrewEilandday")
var DaytypeDropdown = require( "../components/DaytypeDropdown")
var CrewEilanddayListItem = require("../components/CrewEilanddayListItem")


class SearchBar {
    constructor(vnode) {
    }

	view(vnode) {
		return m('input[type=search]');
	}
}


class CrewEilanddayActivities {
    constructor(vnode) {
    }

	view(vnode) {
	    this.activityresource_list = vnode.attrs.creweilandday.activityresource_list;
        let item_data = []
        item_data.push( m('label.w2a-1', {style:"display:inline-block;"}, "Gekaderde Activiteiten: "));
        let activity_data = []
        for (const ar_index in this.activityresource_list) {
            const activityresource = this.activityresource_list[ar_index];
            const name_time = activityresource.activity_name+"("+activityresource.start_time_short+"), "
            activity_data.push( m('span',
                                {style:"display:inline-block;margin-left: 6px;"},
                                 name_time));
        };
        if(activity_data.length == 0) { // label is de eerste. en kennelijk enige.
            item_data.push( m('.msg.w2a-2', {style:"display:inline-block;margin-left: 6px;"},
                              "Geen"));
        } else {
            item_data.push( m('.w2a-2.pull-right', {style:"display:inline-block;margin-left: 6px;"},
                              activity_data));
        }
		return m('.list-item-data.full-width',
   		         {style:'font-size:12px;'},
		         item_data)
	}
}


class MyCrewEilanddayListItem {
    constructor(vnode) {
    }

	view(vnode) {
  	    this.creweilandday = vnode.attrs.creweilandday;
 	    const from_time_to_time = this.creweilandday.from_time_short+'-'+this.creweilandday.to_time_short;
        let item_data = [
            m('.pull-left', {style: 'width:23vw'}, this.creweilandday.day),
            m('.pull-left', {style: 'width:23vw'},  from_time_to_time),
        ]
        const _dagsoort_role = this.creweilandday.day_type_name+" - "+this.creweilandday.role_display;
        item_data.push( m('.daytype_role.pull-left',
                          {style: 'width:49vw'},
                          m('.pull-right', _dagsoort_role)))

		return m('.list-item-data.full-width',
    		     {style:'font-size:12px;'},
		         item_data)
	}
}


class MyCrewEilanddayList {

    constructor(vnode) {
    }

	view(vnode) {
        if (CrewEilandday.my_creweiland_count == 0) {
            return m('span.evg_message', "Geen rooster gevonden");
        }

        let items = [];
        let date = 0;
        let creweilandday_activities = null;
        let date_data = null;
        // .map is sneller, maar moet 1 Array retourneren
        //  dat gebeurt hier dus niet.
        // LT heeft gelijk: dit is niet mooi
        CrewEilandday.my_creweiland_list.forEach((creweilandday) => {
            // 'merge' creweilandday data with the same day
            if (creweilandday.day!=date) {
                //  add 'old' date_data to items and start of new date_data,
                if(date_data) {
                    date_data.push(creweilandday_activities);
                    items.push(m('li.evg-list-item', date_data));
                }
                date_data = [];
                date = creweilandday.day;
                creweilandday_activities = m(CrewEilanddayActivities, {creweilandday: creweilandday});
            }
            date_data.push(m(MyCrewEilanddayListItem, {creweilandday: creweilandday}));

        });
        // complete the last day, and add to items
        date_data.push(creweilandday_activities);
        items.push(m('li.evg-list-item.media', date_data));
        items.push(m('li.evg-list-item.end-of-list-item',
                     m('span',
                          [ m('span.float-left',"Einde lijst. "),
                            //m('span.float-right',"Kind niet in lijst? Even Melden! ")
                            ]
                            )));
		return m('ul.evg-list', items);
	}
}


class MyCrewScheduleList {
    constructor(vnode) {
              CrewEilandday.loadMyCrewEilandList();
              const crumb = {url:'/mycrewschedulelist',
                             text:'Mijn Rooster'};
              Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("schedule.evg_module",
                       [ m(".label", "Op Eiland komende periode"),
                         m(".evg_list_container_no_filter", m(MyCrewEilanddayList) ),
                       ]
                       )
    }
}




class CrewEilanddayList {
    constructor(vnode) {
    }

	view(vnode) {
        let items = CrewEilandday.creweiland_today_list.map((creweilandday) => {
                if (AppState.daytypeFilter == 0 || // creweilandday.day_type == 6 ||
                    creweilandday.day_type == AppState.daytypeFilter) {
                  return m(CrewEilanddayListItem, {creweilandday,
                                                   withFullName: true})
                }
        });

        if(items.length == 0) {
 		    return m('.evg_message', "Geen Crewlega gevonden");
        } else {
           items.push( m('li.evg-list-item.end-of-list-item',
		                            m('span',
		                              [ m('span.float-left',"Einde lijst. "),
		                                //m('span.float-right',"Crewling niet in lijst? Of juist teveel? Even Melden! ")
		                                ]
		                                )) )
        }
		return m('ul.evg-list', items);
	}
}



/* naar components en hernoemen ? */
class CrewScheduleListShowHide {

  constructor(vnode) {
     this.show = false;
     this.toggleShowHide = this.toggleShowHide.bind(this);
     this.view = this.view.bind(this);
  }

  toggleShowHide(e) {
     this.show = !this.show;
  }

  view(vnode) {
        let crew_list_content = [];
        if (this.show) {
                return m('span',
                         // {onclick: this.toggleShowHide},
                         m('button', {type: "button",
                                      onclick: this.toggleShowHide},
                           "Verbergen Crewlingen"),
                         m(".evg_list_container_one_filter",
                           m(CrewEilanddayList) ))
        } else {
          crew_list_content.push();
        }

        return m('span',
                 m('button', {type: "button",
                              onclick: this.toggleShowHide},
                   "Tonen Crewlingen"))
        }
}

// class CrewEilanddayPage = { // dit is een todo:
class CrewScheduleList {
    constructor(vnode) {
        Breadcrumb.setCrumbs([{url:'/menu', text:'Menu'},
                        {url:'/crewschedulelist', text:'Crewlega\'s'},]);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("schedule.evg_module",
                       [ m( "div.evg_block",
                          [m("label", "Groep"),
                           m(".input",
                             m(DaytypeDropdown))
                          ]),
                         m("div.evg_list_container_no_filter",
                           m(CrewEilanddayList) ),
                       ]
                       )
    }
}



module.exports = {
   MyCrewScheduleList,
   CrewScheduleList,
   CrewScheduleListShowHide
}
