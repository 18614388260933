var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
var Auth = require("../services/Auth")
var Breadcrumb = require("../components/Breadcrumb")
var BlogMsg = require("../models/BlogMsg")
var Child = require("../models/Child")

class BlogMsgCreate {

    constructor(vnode) {
        if(vnode.attrs.Id) {
          this.child_id = vnode.attrs.Id;

          const crumb = {url: '/child/' + vnode.attrs.Id+'/blogmsgwrite',
                         text: "Blog Bericht maken"}
          Breadcrumb.addCrumb(crumb);
        } //else {
            // Door naar foutpagina
        //}

        //this.header_data = [m(Breadcrumb),
        //                    m("span.filter-date", AppState.get_filter_date())]

        this.write_blog_msg = this.write_blog_msg.bind(this);
        this.on_text_leave = this.on_text_leave.bind(this);
        this.on_text_enter = this.on_text_enter.bind(this);
        this.view = this.view.bind(this);
    }

    write_blog_msg(vnode) {
        document.getElementById("send_blog_msg").disabled = true;
        var formData = new FormData(document.getElementById("child_blog"));
        BlogMsg.write_blog_msg(formData);
        var parentCrumb = Breadcrumb.getParent();
        m.route.set(parentCrumb.url);
    }

    on_text_enter(event) {
      // console.log("// hide all other elements "+event);
      event.target.scrollIntoView();

      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          if(item.id==event.target.id) {
              item.rows = "10"; // geselecteerde textarea wat groter maken
          } else {
              item.style.display = 'none';
          }
      })

    }

    on_text_leave(event) {
      //console.log("// show all elements "+event);
      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          item.style.display = '';
          item.rows = "5" // geselecteerde textarea naar 3 regels
      })

    }

    view(vnode) {
        //let header_content; // todo: controleren of dit niet weg kan
        let child_select = null;
        let other_child_select = null;
        if (Child.child) {
            const child = Child.child;
            const child_full_name = ""+child.first_name+" "+child.last_name
            // <TERUG> gaat naar kind pagina
            //header_content =[
            //      m("a", {config: m.route,
            //              href: '#!/child/' + child.id},
            //        m("img", {src:"/static/eiland/img/eiland-72dpi.png"})),
            //      m("h2.view-title", "Bloggen @Keesje"),]
            // enige kind in de lijst
            child_select = m("select.evg_block.hide_on_text_enter",
                              {id:"id_child", name:"child", required:"required", autofocus:"autofocus"},
                                   [  m("option", {value:child.id}, child_full_name),
                                   ]
                                 )
            other_child_select = m("select.evg_block.hide_on_text_enter",
                                 { id:"other_child", autofocus:"autofocus", multiple:"true"},
                                 [ // m("option", "Maak een Keuze"),
                                   Child.on_eiland_today_list.map(function(child){
                                       if (Child.child.id != child.id) {
                                           return m("option",
                                                    {value:child.id},
                                                    child.first_name+" "+child.last_name)}
                                       }
                                   )
                                 ]
           )

        }
        else {
            // <TERUG> gaat naar het menu
            //header_content =[
            //      m("a", {config: m.route,
            //              href: '#!/menu/'},
            //        m("img.header-img", {src:"/static/eiland/img/eiland-72dpi.png"})),
            //      m("h2.view-title", "Bloggen @Keesje"),]
            // select met meerdere kinderen
            child_select = m("select.evg_block",
                                  {id:"id_child", name:"child", required:"required", autofocus:"autofocus"},
                                   [ m("option", "Maak een Keuze"),
                                     Child.child_today_list.map((child) => {
                                                                     return m("option", {value:child.id},
                                                                              ""+child.first_name+" "+child.last_name)}
                                                                )
                                   ]
                                 )
        }

        let blogmsgwrite_form =[ m("form",{id:"child_blog", } ,
                   [    child_select,
                        // Nog even niet erin other_child_select,
                        m("input.evg_block.hide_on_text_enter",
                          {type:"text", id:"id_title", name:"title", required:"true", placeholder:"Titel"}),

                        m("textarea.evg_block.hide_on_text_enter",
                          {id:"id_content", name:"content",
                           rows:"5", cols:"40", required:"true",
                           onfocusin:this.on_text_enter, onfocusout:this.on_text_leave,
                           placeholder:"Blog Bericht, voor comunicatie naar ouders."}),

                        m("input.evg_block.hide_on_text_enter",
                          {type:"file", id:"id_image", name:"image", onchange:this.get_image_file}),
                        m("textarea.evg_block.hide_on_text_enter",
                          {id:"id_image_caption", name:"image_caption",
                           rows:"5", cols:"40",
                           onfocusin:this.on_text_enter, onfocusout:this.on_text_leave,
                           placeholder:"Afbeelding onderschrift"}),

                        m("button.evg_action.hide_on_text_enter",
                          {id:"send_blog_msg",type:"button", onclick:this.write_blog_msg},
                          "Blog Bericht plaatsen"),
                   ])
        ]

        m.render(document.getElementById("header"),
                                         [m(Breadcrumb),
                                          m("span.filter-date", AppState.get_filter_date())]);
        return m("blogmsgwrite.evg_module", blogmsgwrite_form)
    }
}

module.exports = BlogMsgCreate
