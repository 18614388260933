var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")

var ChildCrewRatio = require("../models/ChildCrewRatio")

var CrewScheduleList = require("../views/CrewScheduleList");


class DaytypeChildCrewRatio {

  constructor(vnode) {
     this.showDaytypeChildCrewRatio = false;
     this.toggleShowDaytypeChildCrewRatio = this.toggleShowDaytypeChildCrewRatio.bind(this);
     this.decimal_to_class = this.decimal_to_class.bind(this);
     this.view = this.view.bind(this);

     this.hours = [7,8,9,10,11,12,13,14,15,16,17,18,19]
     this.header_display = [];
     this.header_display.push( m('th', "k/c"));
     this.hours.map((hour)=>{
         if (hour<10) {
             this.header_display.push(m('th',"0"+hour));
         } else {
             this.header_display.push(m('th',""+hour));
         }
        }
     );

  }

  toggleShowDaytypeChildCrewRatio() {
     this.showDaytypeChildCrewRatio = !this.showDaytypeChildCrewRatio;
  }

  decimal_to_class(decimal_value) {
    try{
        if (decimal_value == 0) { // == 0
            return "none"
        } else if (decimal_value < 4.5) { //} # van 0 tot 4,5
            return "red"
        }else if (decimal_value < 5.5) { // # van 4,5 tot 5,5
            return "orange"
        } else if (decimal_value < 7) { // # van 5,5 tot 7
            return "green"
        } else if (decimal_value < 8) { // # van 7 tot 8
            return "lightblue"
        } else if (decimal_value < 99) { // }: # vanaf 8
            return "blue"
        }
    } catch (e) {
        return "Except" // #Fout!
    }
    return decimal_value // # ook niet goed!
  }

  view(vnode) {
        let ratio_crew_content = []
        let ratio_content = [];
        if (this.showDaytypeChildCrewRatio) {
           let hour_count = 7;

           let day_type = AppState.daytypeFilter;
           let day_child_crew_ratio_eiland = ChildCrewRatio.getChildCrewRatioByDaytype(day_type);

           if (day_child_crew_ratio_eiland) {
               ratio_content.push(m('tr.right', this.header_display));
               [0,1,2,3].map((index) => {
                  let ratio_quarter_display = [];
                  const minutes=index*15;
                  ratio_quarter_display.push(m('th',":"+minutes));
                  day_child_crew_ratio_eiland.map((ratio_per_hour) => {
                     let ratio_color_name = "ratio_"+this.decimal_to_class(ratio_per_hour[index]);
                     ratio_quarter_display.push(m('td.'+ratio_color_name, ratio_per_hour[index]));
                  });
                  ratio_content.push(m('tr', ratio_quarter_display));
               })
           }

           if (ratio_content.length == 0) {
                  ratio_content.push( m('tr', m('td', "Geen Ratio Beschikbaar")));
           }

          ratio_crew_content.push( m('button', {type: "button",
                                                onclick: this.toggleShowDaytypeChildCrewRatio},
                                     "Verbergen Kind/Crew ratio"));
           ratio_crew_content.push( m('table.ratio_data',
                                      {onclick: this.toggleShowDaytypeChildCrewRatio},
                                      ratio_content))
        } else {
          ratio_crew_content.push( m('button', {type: "button",
                                                onclick: this.toggleShowDaytypeChildCrewRatio},
                                     "Tonen Kind/Crew ratio"));
        }


        //if (this.showDaytypeChildCrewRatio) {
           // crew kan alleen gekozen als de ratio wordt getoond
        //   ratio_crew_content.push(m(CrewScheduleList.CrewScheduleListShowHide));
        //}

        return m('span',
                  ratio_crew_content)
        }
}

module.exports = DaytypeChildCrewRatio
