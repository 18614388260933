var m = require("mithril")
var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")
var LastMinuteOffer = require("../models/LastMinuteOffer")
var LastMinuteClaim = require("../models/LastMinuteClaim")


class OfferListItem {

    constructor(vnode) {
      this.lastminuteoffer = vnode.attrs.offer;

      this.view = this.view.bind(this);
    }

    view(vnode) {
        this.from_to = this.lastminuteoffer.start_time+"-"+this.lastminuteoffer.end_time;
        return m('a', { // Todo: dit moet anders config: m.route,
                 href: '#!/lastminuteoffer/' + this.lastminuteoffer.id },
                 [ m('span.pull_left', this.lastminuteoffer.date),
                   m('span', {style: "display:block;"}, ""+this.lastminuteoffer.day_type_name+" "+this.from_to),
                   m('span.pull_right', this.lastminuteoffer.available_places_left)
                 ])

    }
}


class OfferList {

    constructor(vnode) {
      // this.view = this.view.bind(this);
    }

    view(vnode) {
        var offer_list = []
        LastMinuteOffer.list.map( function(lastminuteoffer) {
                // console.log("check 'has_been_seen' note: "+lastminuteoffer.id);
                offer_list.push(m('li.evg-list-item',
                                  m(OfferListItem, { offer: lastminuteoffer},)
                                ));
        });
        if(offer_list.length==0) {
           offer_list.push(m('li.evg-list-item', "Geen Nippertjes"));
        } else {
           offer_list.push(m('li.evg-list-item','Einde lijst'));
        }
        return m('ul.evg-list', offer_list);
    }
}


class ClaimListItem {

    constructor(vnode) {
      // this.view = this.view.bind(this);
    }

    view(vnode) {
        var lastminuteclaim = vnode.attrs.claim;
        return m('a', { // Todo: dit moet anders config: m.route,
                      href: '#!/lastminuteclaim/' + lastminuteclaim.id },
                 [ m('span.pull_left', " "+lastminuteclaim.lastminute_offer_display),
                   m('span', {style: "display:block;"}, m.trust(lastminuteclaim.request)),
                   m('span.pull_right', lastminuteclaim.status_display)
                 ])
    }
}


class ClaimList {

    constructor(vnode) {
      // this.view = this.view.bind(this);
    }

    view(vnode) {
        var claim_list = []
        LastMinuteClaim.list.map( (lastminuteclaim) => {
                claim_list.push(m('li.evg-list-item',
                                  m(ClaimListItem, { claim: lastminuteclaim},)
                                ));
        });
        if(claim_list.length==0) {
           claim_list.push(m('li.evg-list-item', "Geen Nippertjes"));
        } else {
           claim_list.push(m('li.evg-list-item','Einde lijst'));
        }
        return m('ul.evg-list', claim_list);
    }
}


class MyLastMinutePage {

    constructor(vnode) {
      const crumb = {url: '/mylastminute',
                     text: "Nippertjes"}
      Breadcrumb.addCrumb(crumb);

    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("lastminutelist.evg_module",
                       [m("div.evg_block",
                          m("button.evg_action", "Beschikbare Nippertjes"),
                          m('div.evg_list_container_half_page',
                            m(OfferList))
                          ),
                        m("div.evg_block",
                          m("button.evg_action", "Mijn Nippertjes"),
                          m('div.evg_list_container_half_page',
                            m(ClaimList))
                          )
                       ]
                      )
    }
}

module.exports = MyLastMinutePage
