var m = require("mithril")
var EvGService = require("../services/EvGService");
var Auth = require("../services/Auth");
var AppState = require("../services/AppState");
var Breadcrumb = require("../components/Breadcrumb");

var ChildEilanddayActivityResource = require("../components/ChildEilanddayActivityResource");
var ChildImgName = require("../components/ChildImgName")

var ChildEilandday = require("../models/ChildEilandday")
var Child = require("../models/Child");
var ChildNote = require("../models/ChildNote");
var ChildActivityResource = require("../models/ChildActivityResource");
var BlogMsg = require("../models/BlogMsg");
var ChildObservation = require("../models/ChildObservation");


class ChildPageData {

    constructor(vnode) {

    }

	view(vnode) {
	    if(Child.child == null || Child.child.id != vnode.attrs.id) {
			return m('div', Child.status)
		}

  	    this.childeilandday_list = ChildEilandday.getChildEilanddaysForChild(vnode.attrs.id);
        let day_type_list = [];
        this.childeilandday_list.sort((a, b) => (a.delivery_time_short > b.delivery_time_short) ? 1 : -1)
        this.childeilandday_list.forEach((childeilandday) => {
            const delivery_pickup_time = childeilandday.delivery_time_short+'-'+childeilandday.pickup_time_short
            day_type_list.push( m('li.pull-left.evg_activity',
                                  [m('.pull-left.daytype', childeilandday.day_type_name ),
                                   m('.pull-right.start_end_time', delivery_pickup_time)]))
        })

		return m('div.block_container',
		         [  m('.evg_block',
                      [ m(ChildImgName, {child: Child.child }),
                        m('.child-report-container',
                          [m('.child-report', "Notities dagen geleden: "+Child.child.last_note_days_ago),
                           m('.child-report', "Observaties dagen geleden: "+Child.child.last_observation_days_ago),
                           m('.child-report', "Blog dagen geleden: "+Child.child.last_blog_days_ago)
                          ])
                      ]),

                    m('.evg_block',
                      [ m('label', 'Gekaderde Activiteiten:'),
                        m('.value',
                          m(ChildEilanddayActivityResource,  {"id":vnode.attrs.id}))
                      ]),

                    m('.evg_block',
                      [ m('label', 'Groepen:'),
                        m('.value',
                          m('ul.evg_list.list_level_2',{style:'display:contents;'}, day_type_list)),
                      ]),

                    m('.evg_block.list-item-actions',
                     [ m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/blogmsgwrite", },
                              "Blogbericht maken!"),
                       m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/blogmsglist", },
                         "Blogberichten bekijken"),

                       m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/notewrite", },
                         "Notitie maken"),
                       m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/notelist", },
                         "Notities bekijken (#ongelezen "+ChildNote.child_note_marked_unseen_count+")"),

                       m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/observationwrite", },
                         "Observatie maken"),
                       m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/observationlist", },
                         "Observaties bekijken"),
                      ])

		         ])
	}

}


class ChildPage {

    constructor(vnode) {
       if (vnode.attrs.Id) {
           Child.getChild(vnode.attrs.Id);
           ChildNote.getListForChild(vnode.attrs.Id);
           ChildObservation.loadListForChild(vnode.attrs.Id);
           ChildActivityResource.loadChildDayActivityresourceList(vnode.attrs.Id); // Day from AppState.filter_date
           BlogMsg.loadBlogMsgListForChild(vnode.attrs.Id);

           const crumb = {url: '/child/' + vnode.attrs.Id,
                          text: "Kind"}
           Breadcrumb.addCrumb(crumb);
           this.header_data = [m(Breadcrumb),
                               m("span.filter-date", AppState.get_filter_date())]
       } else {
           console.error("ChildPage geen id")
       }
    }

    view(vnode) {
        m.render(document.getElementById("header"), this.header_data);

        return m("child-page.evg_module",
                 m(ChildPageData, {"id":vnode.attrs.Id})
               )
    }
}

module.exports = ChildPage
