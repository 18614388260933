var m = require("mithril")
var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var ChildEilandday = require("../models/ChildEilandday")
var SchoolDropdown = require("../components/SchoolDropdown")
var Breadcrumb = require("../components/Breadcrumb")
// var ChildSchool = require("../models/ChildSchool")
var ChildEilandday = require("../models/ChildEilandday")
var ChildImgName = require("../components/ChildImgName")


class ChildEilanddaySchoolListItem  {

    constructor(vnode) {
       // this._childeilandday = null;
    }

	view(vnode) {
   	    this._childeilandday = vnode.attrs.childeilandday;
        let item_data = [];
        let item_data_1 = []; // eerste blok van item
        let item_data_2 = []; // tweede blok van item
        // console.log("this.childeilandday.child_full: "+this.childeilandday.child_full);
        //let class_name = ".child-data";
        const start_end_time =  this._childeilandday.delivery_time_short+'-'+ this._childeilandday.pickup_time_short;
        item_data_1.push( m('.pull-left',  {style:"width: 30vw;"},
                             [ m('.pull-left', {style: "display:block; width:inherit;"},
                                 this._childeilandday.day_type_name ),
                               m('.start-end-time', {style: "display:block; width:inherit;"},
                                 start_end_time) ]
                            ));

        item_data_2.push( m('.pull-left', {style:"width: 30vw;"},
                             [ m('.pull-left',  {style: "display:block; width:inherit;"},
                                 m('.pull-right', {style:'width:fit-content'}, this._childeilandday.school.name)),
                               m('.pull-left', {style: "display:block; width:inherit;"},
                                 m('.pull-right', {style:'width:fit-content'}, this._childeilandday.school.off_time))
                             ]
                            ));
        item_data.push( item_data_1);
        item_data.push( item_data_2);

		return m('li.evg-list-item.media', [
		             m(ChildImgName, {child: this._childeilandday.child_full}),
		             item_data
                   ])
	}

}

class ChildEilanddaySchoolList {

    constructor(vnode) {
    }

	view(vnode) {
        if (ChildEilandday.childeiland_today_count == 0) {
            return m('span.evg_message', "Geen Kinderen vandaag");
        }
		let displayed_items = [];
		let even_odd = '';
		let items = ChildEilandday.childeiland_today_list.map((childeilandday)=>{

		    // item tonen als 'alles tonen' is geselecteerd,
		    // of als het dagtype gelijk is aan het geselecteerde dagtype
		    if (childeilandday.school) {
		        if(AppState.schoolFilter != 0) {
		           if(childeilandday.school.id == AppState.schoolFilter) {
         			   displayed_items.push( m(ChildEilanddaySchoolListItem,
      			                               { childeilandday: childeilandday,}
			                           ));
		           }
		        } else {
      			   displayed_items.push( m(ChildEilanddaySchoolListItem,
    			                           { childeilandday: childeilandday,}
			                           ));
			    }
			}
		});
		if (displayed_items.length == 0) {
            return m('span.evg_message', "Geen Kinderen");
		} else {
		    // Een extra item toevoegen om expliciet aa te geven dat een lijst is geeindigd
		    displayed_items.push(m('li.evg-list-item.end-of-list-item',
		                            m('span',
		                              [ m('span.float-left',"Einde lijst. "),
		                                m('span.float-right',
		                                  m('a.phonenumber', {href: "tel:0651521379"},
		                                    "Kind niet in lijst? Even Melden! "))]
		                                )))
		}

		return m('ul.evg-list', displayed_items);
	}
}


class ChildSchoolList {

    constructor(vnode) {
       ChildEilandday.loadOnEilandByDate();
       const crumb = {url:"/childschoollist",
                      text:"Kind/School"};
       Breadcrumb.addCrumb(crumb);

    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("schedule.evg_module",
                       [m(SchoolDropdown),
                        m("div.evg_list_container_one_filter",
                          m(ChildEilanddaySchoolList)),
                       ]
                      )
    }
}

module.exports = ChildSchoolList
