var m = require("mithril");

var AppState = require("../services/AppState");
var Auth = require("../services/Auth");
var VersionCheck = require("../services/VersionCheck");

var Breadcrumb = require("../components/Breadcrumb");


class Logout {

    constructor(vnode) {
        const crumb ={url: '/logout',
                        text: "Logout"};
        Breadcrumb.addCrumb(crumb);
    }


    view(vnode) {
        //console.log("Logut.view");
        m.render(document.getElementById("header"),
                 [ m(Breadcrumb),
                   m("span.app-version", "Versie: "+VersionCheck.getVersionNumber())
                 ]);

        return m("form", [
            m('span.evg_block',
              m("button.evg_action", {onclick: () => { Auth.logout(); }, // VersionCheck.activate_new_version();},
                                      type:"button"},
                                      "Uitloggen")
             )
        ])
    }
}


module.exports = Logout
