var m = require("mithril")
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth");
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var Child = require("../models/Child");

//import { ChildImgName } from '../components/ChildList'
var ChildImgName = require("../components/ChildImgName")


class ChildList {

    constructor(vnode) {
        // this.child_list = vnode.attrs.childList;
    }

    view(vnode) {
        let my_child_list = [];
        Child.my_child_list.map( function(child) {
                const child_data =[ m('.pull-left',
                                      {style: 'font-weight: bold; width: -webkit-fill-available;'},
                                      // {style:"display:block; font-weight: bold; width: -webkit-fill-available;"},
                                      child.first_name),
                                   // m('div.child-subdata', "Blog Data"),
                                   // m('div.child-subdata', "Mentor Data"),
                                   // m('div.child-subdata', "Gekaderde Activiteit Data"),
                                   // m('div.child-subdata', "Rooster Data"),
                                   ]
                // const _child_mugshot_url = Auth.getDomain()+child.mugshot_url;
                let mugshot_url = child.mugshot_url;
                if (!mugshot_url.startsWith("http")) {
                    mugshot_url = Auth.getDomain()+mugshot_url;
                }
                my_child_list.push(m('li.evg-list-item.media.caretaker',
                                     m('a', {href: "#!/mychild/"+child.id},
                                        [ m('img.media-object.medium.pull-left', {src: mugshot_url}),
                                          m('.list-item-data.pull-left.medium', child_data)
                                         ]
                                        )
                                        )
                                   );
        });
        if(my_child_list.length==0) {
           my_child_list.push(m('li.evg-list-item', "Geen Kinderen"));
        }
        return m('ul.evg-list', my_child_list);
    }
}

class MyChildList {

    constructor(vnode) {
              Child.loadMyChildren();
              const crumb = {url:'/mychild',
                             text:'Mijn kinderen'};
              Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("mychildlist.evg_module",
                       [ m("div.evg_list_container", m(ChildList)),
                       ]
                      )
    }
}

module.exports = MyChildList
