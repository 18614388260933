var m = require("mithril")
var Auth = require("../services/Auth");
var Child = require("../models/Child");


/**
 * LastminuteClaim
 *
 */
class LastminuteClaim {

    constructor() {
      this.count = 0;
      this.list = [];

      this.loadLastMinuteClaimList = this.loadLastMinuteClaimList.bind(this);
      this._receiveLastMinuteClaimList = this._receiveLastMinuteClaimList.bind(this);
      this.fetchLastMinuteClaimById = this.fetchLastMinuteClaimById.bind(this);

    }

    loadLastMinuteClaimList() {
        //console.log(" loadLastMinuteClaimList()");
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/my-lastminute-claim-list/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveLastMinuteClaimList);
    }

    _receiveLastMinuteClaimList(result) {
        // console.log("receiveLastMinuteClaimList: "+result.count);
        this.list = result.results;
        this.count = result.count;
        m.redraw(); //voor tonen van resultaat in bv menu
    }

    fetchLastMinuteClaimById(lastminuteclaim_id) {
       for( const the_index in this.list) {
           const lastminuteclaim = this.list[the_index];
           // console.info(lastminuteclaim.id+"=="+lastminuteclaim_id);
           if (lastminuteclaim.id == lastminuteclaim_id) {
               return lastminuteclaim
           } else {
               console.info("reject "+lastminuteclaim);
           }
       }
       return null
    }




}

module.exports = new LastminuteClaim()
