var m = require("mithril")
// import {DaytypeDropdown, ChildRelatedDaytypeDropdown } from "../components/DaytypeDropdown";
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
// var CrewEilandday = require("../models/CrewEilandday")
var ActivityResource = require("../models/ActivityResource")
var ActivityResourceListItem = require("../components/ActivityResourceListItem")


class SearchBar {
	view(vnode) {
		return m('input[type=search]');
	}
}




class ActivityResourceListByDaytype {

    constructor(vnode) {
    }

	view(vnode) {
	    // console.log("EvGService.creweiland_today_count: "+EvGService.creweiland_today_count);
        if (ActivityResource.count == 0) {
            return m('.evg_message', "Geen Activiteiten gevonden");
        }

        let items = [];
        // var date = 0;
        // var creweilandday_activities;

        ActivityResource.list.map((activityresource) => {
            if (AppState.daytypeFilter == 0 || // activityresource.day_type == 6 ||
		        activityresource.day_type == AppState.daytypeFilter) {
                items.push( m(ActivityResourceListItem, {activityresource,
                                                         withFullName: true}));
            }
        });

        if(items.length == 0) {
 		    return m('.evg_message', "Geen Activiteiten gevonden");
        } else {

        }

		return m('ul.evg-list.list_level_1', items);
	}
}


class ActivityResourceList {

    constructor(vnode) {
       ActivityResource.loadFromServer();
       const crumb = {url:"/activityresourcelist",
                      text:"Gekaderde Activiteiten"};
       Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                  ])

              return m("schedule.evg_module.scrollable",
                       [m( "div.evg_block",
                          [m("label", "Groep"),
                           m(".input",
                             m(DaytypeDropdown))
                          ]),
                        m("div.evg_list_container_one_filter",
                           m(ActivityResourceListByDaytype) ),
                       ]
                       )
    }
}

module.exports = ActivityResourceList
