var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var Schoollogistic = require("../models/Schoollogistic")


class SchoollogisticListItem {
    view(vnode) {
        this.schoollogistic = vnode.attrs.schoollogistic;
        let schoollist_data = [];
        // op tijd sorteren doen we locaal
        this.schoollogistic.school_list_children.sort((a, b) => (a.school_offtime > b.school_offtime) ? 1 : -1);
        this.schoollogistic.school_list_children.map((school_children) => {
             schoollist_data.push(
                  m('li.schoollogistic-school-children',
                     [m('.name-time', school_children.school_name + " ("+school_children.school_offtime +")"),
                      m('.children', school_children.children)]) )
        })

        return m('li.evg-list-item.media',
                 [m('.schoollogistic-crew-name', this.schoollogistic.crew_full_name),
                   m('ul.schoollogistic-schoollist', schoollist_data),
                   m('.schooollogistic-extra-info',this.schoollogistic.extra_info)])
    }
}


class SchoollogisticList {
    view(vnode) {
        let items = []
        Schoollogistic.list.map((schoollogistic) => {
           items.push(m(SchoollogisticListItem, {schoollogistic: schoollogistic}));
        })

        items.push(m('li.evg-list-item.media', 'Einde Lijst'));
		return m('ul.evg-list', items);
    }
}

class SchoollogisticPage {

    constructor(vnode) {
      const crumb = {url: '/schoollogistic',
                     text: "Schoollogistiek"}
      Breadcrumb.addCrumb(crumb);
    }


    view(vnode) {
      m.render(document.getElementById("header"),
         [m(Breadcrumb),
          m("span.filter-date", AppState.get_filter_date())
         ]);

        return m("schoollogistic-page.evg_module",
                m("div",{style:"overflow-y: scroll; height: 100vh;"},
                   m(SchoollogisticList)
                 )

        )


    }
}

module.exports = SchoollogisticPage
