var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
var School = require("../models/School")


class SchoolDropdown {
  view(vnode) {
    return m('select.evg_block',
             {onchange: function (ev) { AppState.setSchoolFilter( ev.target.value) }},
             [
              m('option', { value: 0 }, "Alle Scholen tonen"),
              School.school_list.map(function(school) {
                if (school.id==AppState.schoolFilter) {
                   return m('option', { value: school.id, selected:"selected" }, school.name)
                }
                return m('option', { value: school.id }, school.name)
              })
             ])
  }
}

module.exports = SchoolDropdown
