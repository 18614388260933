var m = require("mithril")
var Auth = require("../services/Auth");
var Child = require("../models/Child");


/**
 * get/(or create Blog Messages for a given Child
 *
 */
class BlogMsg {

    constructor() {
      this.count = 0;
      this.list = []
      this.child_blogmsg_list = [];
      this.child_blogmsg_count = 0;

      this.write_blog_msg = this.write_blog_msg.bind(this);
      this._receiveWriteBlogMsgResult = this._receiveWriteBlogMsgResult.bind(this);
      this._receiveBlogMsgListForChild = this._receiveBlogMsgListForChild.bind(this);
      this.loadBlogMsgListForChild = this.loadBlogMsgListForChild.bind(this);
      // this.= = this..bind(this);
    }

    _receiveWriteBlogMsgResult(result) {
    }

    write_blog_msg(formData) {

        var request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/child/"+Child.child.id+"/blog/create/",
            body: formData,
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this._receiveWriteBlogMsgResult)
    }

    _receiveBlogMsgListForChild(result) {
        this.child_blogmsg_list = result.results;
        this.child_blogmsg_count = result.count;
    }

    loadBlogMsgListForChild(child_id) {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/"+child_id+"/blog/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveBlogMsgListForChild)

    }



}

module.exports = new BlogMsg()
