var m = require("mithril")
var AppState = require("../services/AppState")
var Auth = require("../services/Auth");

/* has DaytypeNote and ChildNote management */

class EilandNote {

    constructor() {
        this.have_seen_note = this.have_seen_note.bind(this);
        this._have_seen_response = this._have_seen_response.bind(this);
    }

    _have_seen_response(response) {
        console.log(response.status, response.body)
        this.marked_unseen_count = 0;
        this.list.forEach( (note) => {if(!note.marked_as_seen){this.marked_unseen_count++}} );
        console.log("this.marked_unseen_count: "+this.marked_unseen_count);
    }

    have_seen_note(note_id) {
        var request_data = {
            method: "PUT",
            url: Auth.getDomain()+"/api/note/"+note_id+"/mark-as-seen/",
            extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._have_seen_response)
                .catch((e) => {
                    console.log("EilandNote note has been seen Error: "+e);
                 });
    }
}

module.exports = EilandNote
