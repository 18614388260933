var m = require("mithril")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var CaretakerChangeRequest = require("../models/CaretakerChangeRequest")

/**
from changerequest_mgt.models.py
REQUEST_STATUS_WAIT_EILAND = 1  # eiland must response
REQUEST_STATUS_WAIT_CARETAKER = 2  # caretaker must response
REQUEST_STATUS_GRANTED = 3  # request is granted
REQUEST_STATUS_REJECTED = 4  # request is rejected
REQUEST_STATUS_CLOSED = 5  # request is closed

REQUEST_STATUS_CHOICES = (
    (REQUEST_STATUS_WAIT_EILAND, 'Wacht op Reactie Eiland'),
    (REQUEST_STATUS_WAIT_CARETAKER, 'Wacht op Reactie Ouder'),
    (REQUEST_STATUS_GRANTED, 'Verzoek ingewilligd'),
    (REQUEST_STATUS_REJECTED, 'Verzoek afgewezen'),
    (REQUEST_STATUS_CLOSED, 'Verzoek gesloten')
)
**/

class ChangerequestListItem {

    constructor(vnode) {
    }

    renderStatus(status) {
        if(status==1) {return "Wacht op Reactie Eiland"}
        if(status==2) {return "Wacht op Reactie Ouder"}
        if(status==3) {return "Verzoek ingewilligd"}
        if(status==4) {return "Verzoek afgewezen"}
        if(status==5) {return "Verzoek gesloten"}
        // if(status==1) {return ""}
        return("Error["+status+"]Error")
    }

    view(vnode) {
       // console.log(" ChangerequestListItem: "+ vnode.attrs.changerequest);
       let _request_content = [];
       _request_content.push(m('.request'
                               , m('label', "Aanvraag")
                               , m('.pull-right',vnode.attrs.changerequest.request)));
       _request_content.push(m('br'));
       _request_content.push(m('.state'
                               ,m('label', "Status")
                               ,m('.pull-right',this.renderStatus(vnode.attrs.changerequest.status))));
       if(vnode.attrs.changerequest.reply) {
       _request_content.push(m('br'));
       _request_content.push(m('.reply', "Laatste Antw EvG: ",
                               m('.pull-right',vnode.attrs.changerequest.reply)));
       }
       return m('li.evg-list-item', {style: 'width: 100%;'},
                 _request_content );
    }
}

class CaretakerChangerequestList {

    constructor(vnode) {
     // this.view = this.view.bind(this);
    }

    view(vnode) {
        if (CaretakerChangeRequest.status == 'Ophalen Data') {
            return m('span.evg_message', ChangeRequest.status);
        }

        // todo: naar beneden (test op _displayed_items
        if (CaretakerChangeRequest.list.length == 0) {
            return m('span.evg_message', "Geen Aanpassingsverzoeken");
        }

        let _displayed_items = [];
        CaretakerChangeRequest.list.forEach((changerequest) => {
            _displayed_items.push( m( ChangerequestListItem, {changerequest: changerequest}) ) ;
        });

        if (_displayed_items.length == 0) {
            return m('span.evg_message', "Geen aanvragen. "+ChildEilandday.status);
		} else {
		    // Een extra item toevoegen om expliciet aa te geven dat een lijst is geeindigd
		    _displayed_items.push(m('li.evg-list-item.end-of-list-item',
		                            m('span',
		                              [ m('span.float-left',"Einde lijst. "),
		                                // m('span.float-right',"Kind niet in lijst? Even Melden! ")
		                                ]
		                                )))
		}


        return m('ul.evg-list', _displayed_items);
    }
}


class CaretakerChangerequestPage {

    constructor(vnode) {
      const crumb = {url: '/mychangerequest',
                     text: "Aanpassingsverzoeken"}
      Breadcrumb.addCrumb(crumb);

      // this.view = this.view.bind(this);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("changerequestlist.evg_module",
                       [// m("div", "Rooster Aanpassingsverzoeken"),
                        m(CaretakerChangerequestList),
                       ]
                      )
    }
}

module.exports = CaretakerChangerequestPage
