var m = require("mithril")
var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var MentorChild = require("../models/MentorChild")
var ChildEilandday = require("../models/ChildEilandday")
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var ChildImgName = require("../components/ChildImgName")
var Breadcrumb = require("../components/Breadcrumb")


class MentorChildListItem {
    constructor(vnode) {
       this.child = vnode.attrs.child;
    }

	view(vnode) {
        var item_data = []
        var item_data_1 = []
        var item_data_2 = []

        var class_name=".child-image-data"

        //item_data_1.push( m('.pull-left',
        //                    "Notitie gegevens"))
        //item_data_2.push( m('.float_bottom_right', {style:"bottom: -81px;"},
        //                    "Crew-Notie Gegevens"))




        item_data.push(m('.pull-left', { style:"width:20vw"},
                         item_data_1));
        item_data.push(m('.pull-left', { style:"width:30vw"},
                         item_data_2));

		return m('li.evg-list-item-noclearfix.clearfix.media', [
			m('a', { // config: m.route,
				    href: '#!/child/' + vnode.attrs.child.id + "/mentorinfo/"
			       },
			   [
			      m(ChildImgName, {child: this.child}),
                  m('.list-item-data'+class_name, item_data),
			    ]
			    )
		    ])
	}

}

class ChildList {
    constructor(vnode){
    }

	view(vnode) {
        if (MentorChild.mentorchild_count == 0) {
            return m('.evg_message', "Geen Kinderen vandaag/morgen");
        }
		var displayed_items = [];
		var even_odd = '';
		var foo_bar_items = MentorChild.mentorchild_list.map(function(child) {
    		displayed_items.push( m(MentorChildListItem, { child: child,}) );
		});
		return m('ul.evg-list', displayed_items);
	}
}

class MentorChildList {
    constructor(vnode) {
              const crumb = {url:'/mymentorchildlist',
                             text:'Mentor kinderen'};
              Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              return m("mentorchild.evg_module",
                       [// m(DaytypeDropdown),
                        m("div.evg_list_container", //_one_filter",
                          m(ChildList)),
                       ]
                      )
    }
}

module.exports = MentorChildList
