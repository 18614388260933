var m = require("mithril")
var Auth = require("../services/Auth")

class ChildImg {
   constructor(vnode) {
       // this.child = vnode.attrs.child
   }

   view(vnode) {
     const _child = vnode.attrs.child
     const class_media_obj_size = ".small";
     // const _child_mugshot_url = Auth.getDomain()+_child.mugshot_url;
     let mugshot_url = _child.mugshot_url;
     if (!mugshot_url.startsWith("http")) {
            mugshot_url = Auth.getDomain()+mugshot_url;
     }
     return ( m('.child-img',
                m('img.media-object.pull-left'+class_media_obj_size, { src: mugshot_url }))
             )
   }
}


class ChildName {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child;
     let content = [];
     content.push(m('.first-name', _child.first_name))
     if (vnode.attrs.withLastName) {
         content.push(m('.last-name', _child.last_name));
     }
     return ( m('.pull-left.full-name',
                content))
   }
}


class ChildImgNameSmall {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child
     let class_media_obj_size = ".small";
     let content = []
     if(vnode.attrs.withImg){
         let mugshot_url = _child.mugshot_url;
         if( mugshot_url.startsWith("//www.gravatar.com/")) {
            mugshot_url = "https:"+mugshot_url;
         }

         if (!mugshot_url.startsWith("http")) {
            mugshot_url = Auth.getDomain()+mugshot_url;
         }
         content.push(m('img.media-object.pull-left'+class_media_obj_size, { src: mugshot_url }))
     }
     content.push(m(ChildName, {child: _child,
                                withLastName: vnode.attrs.withLastName}))
     return m('.pull-left', content)

   }
}


module.exports = ChildImgNameSmall
