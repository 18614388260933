var m = require("mithril")
var AppState = require("../services/AppState")
var Child = require("../models/Child")

// Dropdonw of children for 'Ouder/Caretaker)
class MyChildDropDown {

    constructor(vnode) {
        //console.log("MyChildDropdown.constructor: "+vnode.attrs);
        Child.loadMyChildren();
        this.view = this.view.bind(this);
    }

    view(vnode) {
        // console.log("MyChildDropdown.constructor: "+vnode.attrs);
        return m('select.evg_block',
                 { id:"id_child", name:"child", required:"required", autofocus:"autofocus",
                   onchange:function(ev) {AppState.setChildFilter(ev.target.value) } },
                 [ m('option', { value: -1 }, "Selecteer een kind"),
                   // m('option', { value: 0 }, "Mijn kind(eren)"),
                      Child.my_child_list.map(function(child) {
                           return m('option',
                                    { value: child.id,
                                      selected: (AppState.getChildFilter()==child.id ? "true" : "") },
                                    child.first_name);
                      })
                 ])
    }
}

module.exports = MyChildDropDown
