var m = require("mithril")
var AppState = require("../services/AppState")
var Child = require("../models/Child")


class ChildDropDown {

  constructor(vnode) {
      Child.loadChildrenToday();
  }

  view(vnode) {
    return m('select',
             {onchange: function (ev) { AppState.setChildFilter( ev.target.value) }},
             [ m('option', { value: 0 }, "Alle kinderen tonen"),
                  Child.on_eiland_today_list.map( (person) => {
                    const person_fullname = person.first_name+" "+person.last_name;
                    if (person.id == AppState.childFilter ) {
                       return m('option', { value: person.id, selected: "selected"}, person_fullname);
                    } else {
                       return m('option', { value: person.id}, person_fullname);
                    }
                  })
             ])
  }

}

module.exports = ChildDropDown
