var m = require("mithril")
// var EvGService = require("../services/EvGService");
var Auth = require("../services/Auth");


class School   { // ES6

    constructor() {
      this.school_list = [];
      this.school_list_count = 0;
      this.loadSchoolList = this.loadSchoolList.bind(this);
      this.receiveData = this.receiveData.bind(this);
    }

    receiveData(result) {
        this.school_list_count = result.count;
            this.school_list = result.results;
    }

    loadSchoolList() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/school/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this.receiveData)
                .catch((e) => {
                        console.log("loadSchoolList Error: "+e);
                })
    }


}

module.exports = new School()
