var m = require("mithril")

class AppState  {

    constructor(vnode) {
        // console.log("AppState.constructor");
        this.saveAppState = this.saveAppState.bind(this); // used in the try-catch below

        try {
            // Try the localStorage  first for authentication data
            let _appState = localStorage.getItem('appState');
            this.daytypeFilter = 0; // _appState;
            this.schoolFilter = 0;
            this.childFilter = 0; // 0 = alle kindeen, >0 een kind
            this.childOrder = 0; // 0 =
            this.crewFilter = 0; // 0 = alle crew, >0 een crewling
            this.urgencyFilter = 0;
            this.fooFar = "BarFoo";
            this.showDaytypeChildCrewRatio = false;
            this.overrideHasSeen = false;
            this.filter_date = new Date(); // this.getDateToday();

        } catch (err) {
            console.error("AppState Error:"+err);
            // ouwe-meuk fout. alles 'afkeuren'
            this.daytypeFilter = 0;
            this.schoolFilter = 0;
            this.childFilter = 0; // 0 = alle kindeen, >0 een kind
            this.childOrder = 0; // 0 =
            this.crewFilter = 0; // 0 = alle crew, >0 een crewling
            this.urgencyFilter = 0;
            this.fooFar = "BarFoo";
            this.showDaytypeChildCrewRatio = false;
            this.overrideHasSeen = false;
            this.filter_date = new Date(); // this.getDateToday();
            this.saveAppState()
        }
        console.log("START this.saveAppState()");
        this.saveAppState();
        console.log("END this.saveAppState()");

        this.setDaytypeFilter = this.setDaytypeFilter.bind(this);
        this.setCrewFilter = this.setCrewFilter.bind(this);
        this.setChildFilter = this.setChildFilter.bind(this);
        this.getChildFilter = this.getChildFilter.bind(this);
        this.getChildOrder = this.getChildOrder.bind(this);
        this.setSchoolFilter = this.setSchoolFilter.bind(this);
        this.setUrgencyFilter = this.setUrgencyFilter.bind(this);
        this.getDateToday = this.getDateToday.bind(this);
        this.toggleOverrideHasSeen = this.toggleOverrideHasSeen.bind(this);
        this.set_filter_date = this.set_filter_date.bind(this);
        this.get_filter_date = this.get_filter_date.bind(this);
        this.format_date_to_datum = this.format_date_to_datum.bind(this);
    }


    toggleOverrideHasSeen() {
        this.overrideHasSeen = !this.overrideHasSeen;
        m.redraw();
    }

    setDaytypeFilter(value) {
          // console.log("setDaytypeFilter("+value+")")
          this.daytypeFilter = value;
          m.redraw();
    }

    setCrewFilter(value){
          // console.log("setCrewFilter: "+value);
          this.crewFilter = value;
          m.redraw();
    }

    setChildFilter(value){
          // console.log("setChildFilter: "+value);
          this.childFilter = value;
          m.redraw();
    }

    getChildFilter(){
          return this.childFilter;
    }

    setChildOrder(value){
          //console.log("setChildOrder: "+value);
          this.childOrder = value;
          m.redraw();
    }

    getChildOrder(){
          return this.childOrder;
    }

    setSchoolFilter(value){
        // console.log("setSchoolFilter: "+value);
        this.schoolFilter = value;
        m.redraw();
    }

    setUrgencyFilter(value){
        // console.log("setUrgencuFilter: "+value);
        this.urgencyFilter = value;
        m.redraw();
    }

    getDateToday() {
        let date = new Date();
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return year + '-' + (monthIndex+1) + '-' + day;
    }

    set_filter_date(date) {
        var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
        ];
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        // console.log("datum: "+ year + '-' + (monthIndex+1) + '-' + day);
        this.filter_date = date;
    }

    get_filter_date() {
        let day = this.filter_date.getDate();
        let monthIndex = this.filter_date.getMonth();
        let year = this.filter_date.getFullYear();
        return year + '-' + (monthIndex+1) + '-' + day
    }

    get_filter_date_for_api() {
        let day = this.filter_date.getDate();
        let monthIndex = this.filter_date.getMonth();
        let year = this.filter_date.getFullYear();
        return year + '-' + (monthIndex+1) + '-' + day
    }

    get_filter_date_raw() {
        return this.filter_date
    }

    format_date_to_datum(date) {
        const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        return year + '-' + (monthIndex+1) + '-' + day;
    }

    saveAppState(){
        //let _key_list = Object.keys(this);
        //console.log( " _key_list: "+_key_list);
        // let _key_list = Object.keys(this);
        let _cur_state = Object.fromEntries(Object.entries(this).map(entry => [entry[0], entry[1]]));
        // console.log( " _cur_state.daytypeFilter: "+_cur_state.daytypeFilter);
    }
}

module.exports = new AppState()
