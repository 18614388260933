var m = require("mithril")

var Auth = require("./Auth")
var AppState = require("./AppState")

var buildinfo = require("!webpack-plugin-buildinfo?esModule=false&time&gitHash&gitHashShort!");


class VersionCheck  {

    constructor() {
        // console.log("VersionCheck.constructor buildinfo.time: "+buildinfo.time+" "+buildinfo.gitHashShort   );
        this.version="0.0.0";
        this.version_on_server="0.0.0";
        this.version_array =["0", "0", "0" ];
            this.max_time_no_forced_refresh = (12*60*60*1000); // 12 uur
        this.version_refresh = localStorage.getItem('VersionRefreshTimeStamp') || 0;
        this.delta_version_refresh = 0;
        this.new_version_available = false;

        this.get_buildinfo = this.get_buildinfo.bind(this);
        this.check_version_on_server = this.check_version_on_server.bind(this);
        // this.check_version_refresh = this.check_version_refresh.bind(this);
        this.activate_new_version = this.activate_new_version.bind(this);
        this.is_new_version_available = this.is_new_version_available.bind(this);
        this.getVersionNumber = this.getVersionNumber.bind(this);
        //this.init = this.init.bind(this);

        console.log(
           `Keesje@Mobiel v${buildinfo.gitHashShort} compiled at ${new Date(buildinfo.time ).toISOString()}`);

    }

    get_buildinfo() {
        return buildinfo
    }

    // The version of this application instance
    getVersionNumber() {
        if(this.version == '0.0.0') {
            try {
               // console.log("cordova: "+cordova);
               cordova.getAppVersion.getVersionNumber((version) => {
                                                           //console.log("VersionCheck cordova getVersionNumber: "+version);
                                                           this.version = version;
                                                           this.version_array = version.split('.');
                                                           this.version_as_number = parseInt(this.version.replaceAll('.', ''))
                                                      }
               );
            } catch(error) {
               console.error(error);
            }
        }
        return this.version
    }


    parseXML(data) {
         console.log("parseXML: "+data);
         return "x.y.z"
    }

    check_version_on_server() {
        // console.log("check_version_on_server buildinfo :"+buildinfo);
        let request_data = {
                    method: "GET",
                    url: Auth.getDomain()+"/api/mobileversion/",
                    extract: function(xhr) { return { status: xhr.status, body: JSON.parse(xhr.responseText)}}
        }
        Auth.authorize_request_data(request_data);

        return m.request(request_data)
                .then((response) => {
                    if(response.status != 200) {
                       return
                    }
                    this.version_on_server = response.body.results[0].version;
                    this.version_on_server_as_number = parseInt(this.version_on_server.replaceAll('.', ''));
                })
    }

    is_new_version_available() {
        //console.log("this.version:"+this.version)
        //console.log("this.version_on_server:"+this.version_on_server);
        if(this.version=="0.0.0"||this.version_on_server=="0.0.0") {
            return false;
        }
        this.new_version_available =  (this.version!=this.version_on_server);
        // console.log("this.new_version_available: "+this.new_version_available);
        return this.new_version_available
    }


    activate_new_version() {
        // set the new refresh timestamp to 12 hours after now
        // const new_version_refresh = Math.floor(Date.now())+this.max_time_no_forced_refresh;
        // this.new_version_refresh = new_version_refresh;
        // localStorage.setItem('VersionRefreshTimeStamp', new_version_refresh);
        if(this.version=="0.0.0"||this.version_on_server=="0.0.0") {
            return; // men is er nog niet klaar voor
        }
        // console.log("Activate Reload For New Version...");
        m.route.set(""); // Leegmaken Shebang
        const new_version = this.version_array.join('-');
        const new_version_url = 'https://mobiel.keesje.eilandvangroningen.nl/index-'+new_version+'.html';
        // console.log('new_version_url: '+new_version_url);
        window.location.href = new_version_url;
        // location.reload(true);
    }

}


module.exports = new VersionCheck()
