var m = require("mithril")
var EvGService = require("../services/EvGService");
var Auth = require("../services/Auth");
var AppState = require("../services/AppState");
var Breadcrumb = require("../components/Breadcrumb");

//var CrewImgName = require("../components/CrewImgName")
var CrewEilandday = require("../models/CrewEilandday")
var Crew = require("../models/Crew");
var ActivityResource = require("../models/ActivityResource");

var CrewEilanddayListItem = require("../components/CrewEilanddayListItem")
var ActivityResourceListItem = require("../components/ActivityResourceListItem")


class CrewName {
    constructor(vnode) {
    }

	view(vnode) {
    }
}

class CrewPageData {

    constructor(vnode) {
    }

	view(vnode) {
		if(Crew.crew == null || Crew.crew.id != vnode.attrs.id) {
			return m('div', Crew.status)
		}

	    let activityresource_list = [];
	    ActivityResource.list.forEach((activityresource) => {
                                         if(activityresource.crew == vnode.attrs.id) {
                                              console.log(activityresource.id+" Gevonden "+activityresource.crew+" == "+vnode.attrs.id);
                                              activityresource_list.push( m(ActivityResourceListItem, {activityresource}));
                                         }
                                    })
        // console.log("activityresource_list.length: "+activityresource_list.length);
        if(activityresource_list.length == 0) {
 		    activityresource_list.push( m('.evg_message', "Geen Gekaderde Activiteiten gevonden"));
        } else {
            activityresource_list.push( m('.evg_message', "Einde lijst"))
        }

	    let day_type_list = CrewEilandday.creweiland_today_list.map((creweilandday) => {
	        if(creweilandday.crew==vnode.attrs.id) {
                  return m(CrewEilanddayListItem, {creweilandday,
                                                   withName: false});
	        }
        });
        if(day_type_list.length == 0) {
		    day_type_list.push(m('.evg_message', "Geen Roostering gevonden"));
        } else {
            day_type_list.push( m('.evg_message', "Einde lijst"))
        }

		return m('div.block_container',
		         [  m('.evg_block',
                      [m('.first-name-big', Crew.crew.first_name ),
                       m('.last-name-big', Crew.crew.last_name )
                      ]),

                    m('.evg_block',
                      [ m('label', 'Gekaderde Activiteiten:'),
                        m('.evg-groepen',
                          m('ul.evg_list.list_level_2', activityresource_list)),
                      ]),

                    m('.evg_block',
                      [ m('label', 'Groepen:'),
                        m('.evg-groepen',
                          m('ul.evg_list.list_level_2', {style:'display:contents;'}, day_type_list)),
                      ]),
		         ])
	}

}


class CrewPage {

    constructor(vnode) {
       if (vnode.attrs.Id) {
           Crew.getCrew(vnode.attrs.Id);
           // CrewActivityResource.loadCrewDayActivityresourceList(vnode.attrs.Id); // Day from AppState.filter_date
       }

       const crumb = {url: '/crew/' + vnode.attrs.Id,
                      text: "Crewling"}
       Breadcrumb.addCrumb(crumb);
       this.header_data = [m(Breadcrumb),
                           m("span.filter-date", AppState.get_filter_date())]
    }

    view(vnode) {
        m.render(document.getElementById("header"), this.header_data);

        return m("crew-page.evg_module",
                 m(CrewPageData, {"id":vnode.attrs.Id})
               )
    }
}

module.exports = CrewPage
