var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");
var ChildImgName = require("../components/ChildImgName")


class MentorChild {
    constructor() {
        this.mentorchild_count = 0;
        this.mentorchild_list = [];
        this.loadMentorChildList = this.loadMentorChildList.bind(this);
    }

    loadMentorChildList() {
        const request_data = {
            method: "GET",
            url: Auth.domain+"/api/my-mentorchild/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then((result) => {
                       this.mentorchild_count = result.count;
                       this.mentorchild_list = result.results;
                })
                .catch(function(e){
                        console.log("loadMentorChildList Error: "+e);
                })
    }

}

module.exports = new MentorChild()