var m = require("mithril")
var Auth = require("../services/Auth");
var LastMinuteClaim = require("./LastMinuteClaim");

/**
 * LastminuteOffer
 *
 */
class LastminuteOffer  {

    constructor() {
      this.count = 0;
      this.list = [];

      this.loadLastMinuteOfferList = this.loadLastMinuteOfferList.bind(this);
      this._receiveLastMinuteOfferList = this._receiveLastMinuteOfferList.bind(this);
      this.claimLastMinuteOffer = this.claimLastMinuteOffer.bind(this);
      this.fetchLastMinuteOfferById = this.fetchLastMinuteOfferById.bind(this);
      // this. = this..bind(this);
    }

    loadLastMinuteOfferList() {
        //console.log(" loadLastMinuteOfferList()");
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/lastminute/",
            // extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveLastMinuteOfferList);

    }

    _receiveLastMinuteOfferList(result) {
        // console.log("receiveLastMinuteOfferList: "+result.count);
        // console.log("receiveLastMinuteOfferList: "+result.results);
        this.list = result.results;
        this.count = result.count;
        m.redraw(); //voor tonen van resultaat in bv menu
    }

    claimLastMinuteOffer(lastminute_id, formData) {
        //console.log("formData"+formData); //lastminute_id
        var request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/lastminute/"+lastminute_id+"/claim/",
            body: formData
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then( (result)=>{
                     this.loadLastMinuteOfferList(); // herladen  nippertjeslijst
                     LastMinuteClaim.loadLastMinuteClaimList(); // herladen mijn claim list
                     m.route.set("/lastminutelist")
                 })
         .catch((e)=>{
                     console.log("LastMinuteOffer claim Error: "+e);
                })

    }

    fetchLastMinuteOfferById(lastminute_id) {
       for( const the_index in this.list) {
           const lastminute = this.list[the_index];
           // console.info(lastminute.id+"=="+lastminute_id);
           if (lastminute.id == lastminute_id) {
               return lastminute
           } // else {
           //    console.info("reject "+lastminute);
           // }
       }
       return null
    }

}

module.exports = new LastminuteOffer()
