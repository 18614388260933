var m = require("mithril")
var AppState = require("../services/AppState")
var DayType = require("../models/DayType")



class DaytypeDropDownFilter {

  view(vnode) {
    return m('select',
             {id:"id_day_type", name:"day_type",
              onchange: function (ev) { AppState.setDaytypeFilter( ev.target.value) }},
             [m('option',
              { value: 0 }, "Alle groepen tonen"),
              DayType.list.map((daytype) => {
                   if (daytype.id==AppState.daytypeFilter) {
                       return m('option', { value: daytype.id, selected:"selected" }, daytype.name)
                   }
                   return m('option', { value: daytype.id }, daytype.name)
              })
    ])
  }

}


class DaytypeDropDownSelect {

  view(vnode) {
    return m('select',
             {id:"id_day_type", name:"day_type", required:"required",
              onchange: function (ev) { AppState.setDaytypeFilter( ev.target.value) }},
             [m('option', {value:''}, "Kies een groep"),
              DayType.list.map((daytype) => {
                   if (daytype.id==AppState.daytypeFilter) {
                       return m('option', { value: daytype.id, selected:"selected" }, daytype.name)
                   }
                   return m('option', { value: daytype.id }, daytype.name)
              })
    ])
  }

}

class DaytypeDropDownMultipleSelect {

  view(vnode) {
    return m('select',
             {id:"id_day_type", name:"day_type", required:"required", multiple:"true",
              onchange: function (ev) { AppState.setDaytypeFilter( ev.target.value) }},
             [m('option', {value:''}, "Kies een groep"),
              DayType.list.map((daytype) => {
                   if (daytype.id==AppState.daytypeFilter) {
                       return m('option', { value: daytype.id, selected:"selected" }, daytype.name)
                   }
                   return m('option', { value: daytype.id }, daytype.name)
              })
    ])
  }

}

class ChildRelatedDaytypeFilter {

  view(vnode) {
    return m('select.evg_block',
             {id:"id_day_type", name:"day_type",
              onchange: function (ev) { AppState.setDaytypeFilter( ev.target.value) }},
             [m('option', { value: 0 }, "Alle groepen tonen"),
              DayType.list.map(function(daytype) {
                if (daytype.child_related) {
                   if (daytype.id==AppState.daytypeFilter) {
                       return m('option', { value: daytype.id, selected:"selected" }, daytype.name)
                   }
                   return m('option', { value: daytype.id }, daytype.name)
                }
              })
    ])
  }

}

class ChildRelatedDaytypeSelect {

  view(vnode) {
    return m('select.evg_block',
             {id:"id_day_type", name:"day_type",
              onchange: function (ev) { AppState.setDaytypeFilter( ev.target.value) }},
             [m('option', "Selecteer een groep"),
              DayType.list.map(function(daytype) {
                if (daytype.child_related) {
                   if (daytype.id==AppState.daytypeFilter) {
                       return m('option', { value: daytype.id, selected:"selected" }, daytype.name)
                   }
                   return m('option', { value: daytype.id }, daytype.name)
                }
              })
    ])
  }

}


class DaytypeDropDown {

  constructor(vnode) {
         switch (vnode.attrs.dropdownType) {
            case 'filter':
                 this.dropdown = DaytypeDropDownFilter;
                 break;
            case 'childrelatedfilter':
                 this.dropdown = ChildRelatedDaytypeFilter;
                 break;
            case 'select':
                 this.dropdown = DaytypeDropDownSelect;
                 break;
            case 'select-multiple':
                 this.dropdown = DaytypeDropDownMultipleSelect;
                 break;
            case 'childrelatedselect':
                 this.dropdown = ChildRelatedDaytypeSelect;
                 break;
         default: this.dropdown = DaytypeDropDownFilter;
         }
  }

  view(vnode) {
    return m(this.dropdown);
  }
}


module.exports = DaytypeDropDown
