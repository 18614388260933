var m = require("mithril")

var AppState = require("../services/AppState")
var Auth = require("../services/Auth")
var VersionCheck = require("../services/VersionCheck")

var Breadcrumb = require("../components/Breadcrumb")


class Login {

    constructor(vnode) {
        const login_crumb = {url: '/login',
                            text: "Login"};
        Breadcrumb.addCrumb(login_crumb);

        this._checkEmail = this._checkEmail.bind(this);
        this._toggleShowPAssword = this._toggleShowPassword.bind(this);

    }

    _checkEmail(value) {
      // console.log("checkEmail: "+value);
      Auth.setEmail(value);
      // m.redraw();
    }

    _toggleShowPassword() {
      console.log("ToggleShowPWD");
      let inputPwd = document.getElementById("id_password");
          if (inputPwd.type === "password") {
              inputPwd.type = "text";
          } else {
              inputPwd.type = "password";
          }
    }

    view(vnode) {
        m.render(document.getElementById("header"),
                 [  m(Breadcrumb),
                    m("span.app-version", "Versie: "+VersionCheck.getVersionNumber() )
                  ]);

        var form_elements = [
            m('.evg_block',
              m('label',"E-mail"),
              m('.value.input',
                m("input[type=email]", {//oninput: m.withAttr("value", this._checkEmail ),
                                     oninput: (ev)=>{ this._checkEmail(ev.target.value)},
                                     value: Auth.email,
                                     name: "email",
                                     id:"id_auth_email",
                                     placeholder: "E-mail",
                                     autocomplete:"email"})
                )
             ),

            m('.evg_block',
              m('label', "Wachtwoord"),
              m('.input.value',
                m("input[type=password]", {id: 'id_password',
                                           onchange: (ev)=>{ Auth.setPassword(ev.target.value) },
                                           value: Auth.password,
                                           placeholder: "Wachtwoord",
                                           autocomplete:"current-password"})
              )
             ),
            m("button", {onclick: this._toggleShowPassword,
                         type:"button"}, "Tonen/Verbergen Password"),
            m('.evg_block',
              m("button.evg_action", {onclick: Auth.login,
                                      type:"button"},
                                      "Inloggen" )
             )
        ]

        if(Auth.message != "") {
           form_elements.push(m('span.evg_block.error',
                                Auth.message))
        }

        return m("form", form_elements)
    }
}


module.exports = Login
