var m = require("mithril")
var AppState = require("../services/AppState")
// var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")
var Child = require("../models/Child");

var ChildObservation = require("../models/ChildObservation");


class ObservationListItem  {

    constructor(vnode) {
        //this.have_seen_observation = this.have_seen_observation.bind(this);
        //this.renderHaveSeenObservation = this.renderHaveSeenObservation.bind(this);
        this.view = this.view.bind(this);
    }

    remove_have_seen_observation() {
       ChildObservation.have_seen_observation(this.observation.id);
       this.observation.marked_as_seen = true; // tijdelijke test
       m.redraw();
    }

    remove_renderHaveSeenObservation() {
       if( this.observation.marked_as_seen == true) return; // tijdelijke test
       return m(".have_seen", "TAP voor aangeven Gezien");
    }

    view(vnode){
        this.observation = vnode.attrs.observation;
        let _observation_date = this.observation.date;
        return m('li.evg-list-item',
                 {id:"child_"+this.observation.child},
                 [ m( '.date', this.observation.date+' door: '+this.observation.crew_full_name),
                   m( '.text', m.trust(this.observation.text) )
                 ] )
    }
}


class ObservationList {

    constructor(vnode) {
        this.filter_observation = this.filter_observation.bind(this);
    }

    filter_observation(observation) {
        if ( AppState.overrideHasSeen &&
            (AppState.childFilter==note.child_id || AppState.childFilter==0 )) {
            return true
        }
        if (observation.marked_as_seen) {
          return false
        }
        if (AppState.childFilter==0 || AppState.childFilter==note.child_id) {
          if (AppState.urgencyFilter==0 || AppState.urgencyFilter<=observation.urgency) {
              return true
          }
        }
        return false
    }

    view(vnode) {
        let child_id = vnode.attrs.child_id;
        // let filtered_observation_list = ChildObservation.getListForChild(child_id);
        //var filtered_note_list = ChildNote.list.filter( this.filter_note );

        let m_observation_list = [];
        if(ChildObservation.child_observation_list.length==0) {
           m_observation_list.push(m('li.evg-list-item', "Geen Observaties"));
        } else {
           // filtered_observation_list.forEach( (f_observation) => {
           ChildObservation.child_observation_list.forEach( (f_observation) => {
              // console.log("Add "+f_observation);
              m_observation_list.push(m(ObservationListItem, {'observation': f_observation}));
           })
           m_observation_list.push(m('li.evg-list-item.end-of-list-item',
                                   m("span", "Einde lijst")));
        }
        console.log("m_observation_list: "+m_observation_list);
        return m('ul.evg-list', m_observation_list)
    }
}


class ChildObservationList {

    constructor(vnode) {
        console.log("ChildObservationList child: "+vnode.attrs.Id)
        const crumb = {url: '/child/' + vnode.attrs.Id+'/observationlist',
                       text: "Observaties"};
        Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ]);

              if(Child.child==null) {
                  // console.log("Geen Kind")
                  m.route.set("/splash");
              }

              let child_full_name = Child.child.first_name+" "+Child.child.last_name;
              return m("childobservationlist.evg_module",
                       [m("div", "Observaties op "+child_full_name),
                        m("div.evg_list_container_one_filter",  // geen filter, maar bovenstaande div neem ook ruimte in
                          m(ObservationList, {child_id: Child.child.id})),
                       ]
                      )
    }
}

module.exports = ChildObservationList
