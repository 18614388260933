var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");


class ChildActivityResource {

    constructor() {
       this.list =  [];
       this.count = 0;
       this.date_filter = null;
       this.loadChildDayActivityresourceList = this.loadChildDayActivityresourceList.bind(this);
       this._receiveActivityResourceData = this._receiveActivityResourceData.bind(this);
    }


    _receiveActivityResourceData(result) {
        this.count = result.count;
        this.list = result.results;
        this.child_day_activityresource_count = result.count;
        this.child_day_activityresource_list = result.results;
    }

    loadChildDayActivityresourceList(child_id) {
        var request_data = {
            method: "GET",
            // url: Auth.domain+"/api/child/"+child_id+"/activityresource/?date="+AppState.get_filter_date()+,
            url: Auth.domain+"/api/child/"+child_id+"/"+AppState.get_filter_date()+"/activityresource/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveActivityResourceData)
                .catch((e) => {
                   console.error("loadChildDayActivityresourceList Error: "+e);
                })

    }

}

module.exports = new ChildActivityResource()