var m = require("mithril");
var AppState = require("./AppState");
var EvGService = require("./EvGService");


class Auth {

    constructor() {
        const window_location = ""+window.location;
        this.domain = "https://keesje.eilandvangroningen.nl";
        // voor ontwikkel omgeving
        // cordova moet op 8001 zitten
        // of anders 8000. maar dan zitten dingen elkaar in de weg
        if (window_location.startsWith("http://localhost:8000")) { // maar met PRODUCTIE ALS ACHTEREND
            alert("Dit is Development, OP PRODUCTIE");
            // console.log("Dit is Development, OP PRODUCTIE");
            //this.domain = "http://keesje.evg.localhost:8000";
        }
        if (window_location.startsWith("http://localhost:8001")) {
            console.log("Dit is Development, locale django als achterend.");
            this.domain = "http://keesje.evg.localhost:8000";
        }// else {
        //    console.log("Dit is niet Development");
        //}

        this.message = "";
        this.username_message = "";
        this.password = "";
        this.authToken = "";
        this.crew_id = 0;
        this.groups = [];
        try {
            // Try the localStorage  first for authentication data
            let _authToken = localStorage.getItem('authToken');
            if (_authToken != null) {
              this.authToken = JSON.parse(_authToken);
            }
            let _crew_id = localStorage.getItem('crewId');
            if (_crew_id != null) {
              this.crew_id = JSON.parse(_crew_id);
            }
            let _groups = localStorage.getItem('groups');
            if (_groups != null) {
              this.groups = JSON.parse(_groups);
            }

        } catch (err) {
            console.log("Auth Error:"+err);
            // ouwe-meuk fout. alles 'afkeuren'
            this.authToken = "";
            this.crew_id = 0;
            this.groups = [];
        }
        this.username = "";
        this.email = "";

        this.getDomain = this.getDomain.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.setAuthToken = this.setAuthToken.bind(this);
        this.getAuthToken = this.getAuthToken.bind(this);
        this.setCrewId = this.setCrewId.bind(this);
        this.setGroups = this.setGroups.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.hasGroup = this.hasGroup.bind(this);
        this.authorize_request_data = this.authorize_request_data.bind(this);

    }

    getDomain() {
       return this.domain;
    }
    toggleDomain() {
       this.domain = this.dev_domain;
    }

    setAuthToken(authToken) {
        this.authToken = authToken;
        localStorage.setItem('authToken', JSON.stringify(authToken));
    }
    getAuthToken(){
        if(!this.authToken || this.authToken == '') {
           m.route.set("/menu"); // als geen authToken, dan toont Menu alleen 'Login'
        }
        return this.authToken
    }

    setCrewId(id) {
        this.crew_id = id;
        localStorage.setItem('crewId', JSON.stringify(id));
    }

    setGroups(groups) {
        this.groups = groups;
        localStorage.setItem('groups', JSON.stringify(groups));
    }
    getGroups() {
        return this.groups
    }
    hasGroup(group) {
        return this.groups.includes(group)
    }

    setUsername(value) {
        this.username = value;
    }
    setEmail(value) {
        this.email = value;
    }
    setPassword(value) {
        this.password = value;
    }

    authorize_request_data(request_data) {
       request_data.config = (xhr) => {
            xhr.setRequestHeader("Authorization", 'Token ' + this.authToken)
       }
    }

    login() {
        // remove all cookies. they might interfere with csrf_exempt on server
        var request_data = {
            method: "POST",
            url: this.domain+"/api/get-auth-token/",
            body: { username: this.email,
                    password: this.password
            },
            extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        this.message = [];
        m.request(request_data)
         .then((resp) => {
                     // this.message = [];
                     this.username_message = "";
                     if(resp.status==400|resp.status==403){
                         // Cookies.removeAll();
                         var _resp_body = JSON.parse(resp.body)
                         if(_resp_body.non_field_errors[0]) {
                             this.message.push(m('p', _resp_body.non_field_errors[0]));
                         }
                         this.message.push( m('p',
                           "Als inloggen niet lukt, is het mogelijk dat binnen de browsr nog een inlog bij de webapplicatie van keesje actief is.\n"+
                           "Als na uitloggen bij de Web applicatie van  Keesje inloggen nog steeds niet lukt, even bellen met het Eiland"));
                         // console.error("Fout (40x bij Inloggen.\n"+this.message);
                         m.route.set("/login");
                         return
                     }
                     if(resp.status==200||resp.status==201) {
                         var _resp_body = JSON.parse(resp.body)
                         this.setAuthToken(_resp_body.token);
                         this.setCrewId(_resp_body.crew_id);
                         this.setGroups(_resp_body.groups);
                     }
                     m.route.set("/menu");
                   }
               )
         .catch((e) => {
              if(e) {
                  if (e.detail) {
                      this.message.push(m('p', e.detail));
                  }
                  if (e.non_field_errors) {
                      this.message.push(m('p', e.non_field_errors));
                  }
              } else {
                console.log("Geen e in catch")
              }
         })
    }

    logout() {
        this.setAuthToken("");
        this.setGroups([]);
        this.setCrewId(-1);
        // remove all cookies. they might interfere with csrf_exempt on server

        m.route.set("/menu");
    }

    isLoggedIn() {
        if(this.authToken && this.authToken != "") {
            return true
        }
        return false;
    }
}


module.exports = new Auth()
