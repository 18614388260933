var m = require("mithril")
var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")
var AppState = require("../services/AppState");
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var ChildOrderDropdown = require( "../components/ChildOrderDropdown");

// Todo: Hernoemen naar Filtereing En Sorting
// Beter?: hedoen naar SettingsPage
//
//class ChildOrderSelect {
//    view(vnode) {
//       return m('.filter-group',)
//    }
//}


class ChildFilterSelect {
    constructor(vnode) {
    }

    view(vnode) {
       return m('.filter-group',
                     // m( "div.group-title", "Filtering en Sortering op Kinderen"),
                     m( "div.evg_block",
                          [// m(".label-input", // 'daytype_child_related_filter'
                           //    m("label", "Groep"), m(".value.input",
                           //                           m(DaytypeDropdown,
                           //                             {dropdownType: 'childrelatedfilter'}))])),
                           m(".label-input",
                             m("label", "Sortering Kinderen"), m(".value.input", m(ChildOrderDropdown))), // 'child_order'
                           // m(".label-input",
                           //   m("label", "Sortering Crew"), m(".value.input", m(CrewOrderDropdown))) // 'crew_order'
                          ] )
               )
    }
}


class EvGTodayFilter {

    constructor(vnode) {

        const crumb = {  url: '/evgtoday-filter',
                        text: "Filter/Sortering"}
        Breadcrumb.addCrumb(crumb);

        this.header_data = [m(Breadcrumb),
                            m("span.filter-date", AppState.get_filter_date())]

        //this.renderCrewFilterSelect = this.renderCrewFilterSelect.bind(this);
        //this.renderChildFilterSelect = this.renderChildFilterSelect.bind(this);
    }



    view(vnode) {
        m.render(document.getElementById("header"), this.header_data);

        return m( "splash.evg_module.scrollable",
                 [ //  m(".evg_block", this.renderCrewFilterSelect()),
                   m(".evg_block", m(ChildFilterSelect)),
                 ])
    }

}


module.exports = EvGTodayFilter
