var m = require("mithril")
var EvGService = require("../services/EvGService")
var Settings = require("../models/Settings")
var School = require("../models/School")


class ChildOrderDropdown {
  constructor(vnode) {
     // settings: child_order
     this.option_start_time = 0; // Aankomst
     this.option_first_name = 1; // Voornaam
     this.option_age = 2; // leeftijd, op waarde date_of_birth

     this.optionList = [[this.option_start_time, "Aankomst op EvG"],
                        [this.option_first_name, "Voornaam"],
                        [this.option_age, "Leeftijd"]];

     this.generateOptions = this.generateOptions.bind(this);
  }


  generateOptions(){
      let setting_value = Settings.getValue('child_order');
      console.log("_curStat: "+_curStat);
      let _optionList = this.optionList.map((option)=>{
                           return m('option',
                                    {value:option[0], selected:(option[0]==setting_value?"selected":"")},
                                    option[1])
                           })

      return _optionList
  }

  view(vnode) {
    return m('select',
             {onchange: function (ev) { Settings.setValue('child_order', ev.target.value) }},
             generateOptions()
             )
  }
}

module.exports = ChildOrderDropdown
