var m = require("mithril")
var Auth = require("../services/Auth");
var Child = require("../models/Child");
var EilandNote = require("./EilandNote")


/**
 * get/(or create) ChildObservations for a given Child
 *
 */
class ChildObservation { // } extends EilandNote {

    constructor(vnode) {
        this.list = [];
        this.count = 0;
        this._receiveList = this._receiveList.bind(this);
        this.loadList = this.loadList.bind(this);

        this.child_observation_list = [];
        this.child_count = 0;
        this._receiveListForChild = this._receiveListForChild.bind(this);
        this.loadListForChild = this.loadListForChild.bind(this);
        this.getListForChild = this.getListForChild.bind(this);

        this.writeChildObservation = this.writeChildObservation.bind(this);
        this._recieveWriteChildObservation = this._recieveWriteChildObservation.bind(this);
    }

    _receiveList(result) {
        this.list = result;
        this.count = result.length;
        this.marked_unseen_count = 0;
        if(this.list?.length>0) {
            this.list.forEach((observation) => {if(!observation.marked_as_seen){this.marked_unseen_count++}});
        }
        //console.log("this.marked_unseen_count: "+this.marked_unseen_count);
    }

    loadList() {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/observation/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveList);
    }

    _receiveListForChild(result) {
        this.child_observation_list = result.results;
        this.child_count = result.count;
    }

    loadListForChild(child_id) {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/"+child_id+"/observation/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveListForChild);
    }

    getListForChild(child_id) {
         // console.log("getListForChild("+child_id+")"+this.child_observation_list);
         // this.child_observation_list = this.list.filter( (observation) => { return observation.child_id==child_id } );
         this.child_observation_marked_unseen_count = 0;
         // this.child_observation_list.forEach( (observation) => {if(!observation.marked_as_seen){this.child_observation_marked_unseen_count++}} );
         // console.log("getListForChild("+child_id+")"+this.child_observation_list);
         return this.child_observation_list
    }

    _recieveWriteChildObservation(result) {
        this.loadListForChild(Child.child.id); // herladen notities voor kind
    }

    writeChildObservation(formData) {
        // console.log("ChildObservation writeChildObservation: "+formData);
        let request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/child/"+Child.child.id+"/observation/create/",
            body: formData,
            extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this._recieveWriteChildObservation)
         .then(this.getListForChild(Child.child.id))
         .catch((e) => {
                 console.error("ChildObservationCreate Error: "+e);
                })
    }

}

module.exports = new ChildObservation()
