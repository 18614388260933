var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
/* Display Activities and planned activities for given child on given day */
var ChildActivityResource = require("../models/ChildActivityResource");

class ChildEilanddayActivityResource {
  view(vnode) {
    let activity_content = []
    ChildActivityResource.list.map((activityresource)=>{
        const start_end_time = activityresource.start_time_short+"-"+activityresource.end_time_short;
        const _content = [m(".pull-left",  activityresource.activity_name),
                          m(".pull-right.start_end_time", start_end_time)];
        activity_content.push(m('li.pull-left.evg_activity', // .evg_activity.button_choice
                                // {onclick:console.log("test")},
                                _content))
        })

    if (activity_content.length == 0) {
      activity_content.push( m('li.evg_activity', "Geen Gekaderde activiteiten"));
	}

    return m('ul.evg_list.list_level_2',  activity_content)
  }
}

module.exports = ChildEilanddayActivityResource
