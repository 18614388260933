var m = require("mithril")

var Auth = require("../services/Auth")
var AppState = require("../services/AppState")


class Crew  {

    constructor() {
       this.crew = null;
       this.status = "Geen Crewling";
       this.list_count = 0;
       this.list = [];

       this.on_eiland_today_count = 0;
       this.on_eiland_today_list = [];

       // this.loadFromServer = this.loadFromServer.bind(this);
       this.loadAllCrew = this.loadAllCrew.bind(this);
       this._receiveDataAllCrew = this._receiveDataAllCrew.bind(this);


       this._receiveDataCrewToday = this._receiveDataCrewToday.bind(this);
       this.loadAllCrewToday = this.loadAllCrewToday.bind(this);

       this._receiveDataLoadCrew = this._receiveDataLoadCrew.bind(this);
       this.loadCrew = this.loadCrew.bind(this);
       this.getCrew = this.getCrew.bind(this);

    }

    _receiveDataAllCrew(result) {
        this.list_count = result.results
        this.list = result.results
    }

    _receiveDataCrewToday(result) {
        this.on_eiland_today_count = result.count;
        this.on_eiland_today_list = result.results;
    }

    loadAllCrew() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/crew/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDataAllCrew)
                .catch(function(e){
                        alert("loadAllCrew Error: "+e);
                });
    }

    loadAllCrewToday() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/crew/"+AppState.get_filter_date()+"/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDataCrewToday)
                .catch(function(e){
                        alert("load_loadAllCrewToday Error: "+e);
                });
    }


    _receiveDataCrew(result) {
        this.on_eiland_today_count = result.count;
        this.on_eiland_today_list = result.results;
    }

    getCrew(crew_id) {
        console.log("Crew.getCrew: "+crew_id);
        if(this.crew && this.crew.id==crew_id) {
               return;
        }
        for (var crew_index in this.on_eiland_today_list) {
            this.crew = this.on_eiland_today_list[crew_index];
            if (this.crew.id == crew_id) {
                this.status = "Crewling Geladen";
                return; // focus is on cild
            }
        }
        for (var crew_index in this.list) {
            this.crew = this.list[crew_index];
            if (this.crew.id == crew_id) {
                return; //  focus is on crew
            }
        }

        this.crew = null;
        this.status = "Geen Crewling";
        this.loadCrew(crew_id)
    }

    _receiveDataLoadCrew(result) {
        // console.log("DATALOADCREW:"+result )
        result.crew_full_name = result.first_name+" "+result.last_name; // lelijk!!
        this.list.push(result);
        this.crew = result;
        this.status = "Crewling Geladen";
    }

    loadCrew(crew_id) {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/crew/"+crew_id+"/limited/",
        }
        Auth.authorize_request_data(request_data);
        this.status = "Crewling Gegevens Worden Gehaald";
        return m.request(request_data)
                .then(this._receiveDataLoadCrew)
                .catch((e) => {
                     alert("Crew loadCrew Error: "+e );
                });

    }

}

module.exports = new Crew()
