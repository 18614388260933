var m = require("mithril")

var Auth = require("../services/Auth")
var AppState = require("../services/AppState")


class Child  {

    constructor() {
       this.child = null;
       this.status = "Geen Kind";
       this.list_count = 0;
       this.list = [];

       this.my_child_list_count = 0;
       this.my_child_list = [];

       this.on_eiland_today_count = 0;
       this.on_eiland_today_list = [];

       // this.loadFromServer = this.loadFromServer.bind(this);
       this.loadAllChildren = this.loadAllChildren.bind(this);
       this._receiveDataAllChildren = this._receiveDataAllChildren.bind(this);


       this._receiveDataChildrenToday = this._receiveDataChildrenToday.bind(this);
       this.loadChildrenToday = this.loadChildrenToday.bind(this);

       this._loadChildSucces = this._loadChildSucces.bind(this);
       this.loadChild = this.loadChild.bind(this);
       this.getChild = this.getChild.bind(this);

       // Voor de ouders
       this.loadMyChildren = this.loadMyChildren.bind(this);
       this._receiveDataMyChildren = this._receiveDataMyChildren.bind(this);

    }

    _receiveDataAllChildren(result) {
        this.list_count = result.results
        this.list = result.results
    }

    _receiveDataChildrenToday(result) {
        this.on_eiland_today_count = result.count;
        this.on_eiland_today_list = result.results;
    }

    _receiveDataMyChildren(result) {
        this.my_child_list_count = result.results
        this.my_child_list = result.results
    }

    loadAllChildren() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDataAllChildren)
                .catch(function(e){
                        console.log("loadAllChildren Error: "+e);
                });
    }

    loadChildrenToday() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/"+AppState.get_filter_date()+"/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDataChildrenToday)
                .catch(function(e){
                        console.log("load_loadChildrenToday Error: "+e);
                });
    }

    loadMyChildren() {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/my-child/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveDataMyChildren)
                .catch(function(e){
                        console.log("loadMyChildren Error: "+e);
                });
    }

    _receiveDataChild(result) {
        this.on_eiland_today_count = result.count;
        this.on_eiland_today_list = result.results;
    }

    getChild(child_id) {
        // console.log("Child.getChild: "+child_id);
        if(this.child && this.child.id==child_id) {
               this.status = "Kind Gegevens Gereed"
               return;
        }
        this.child = null;
        this.status = "Data Wordt Gezocht";
        for (var child_index in this.on_eiland_today_list) {
            this.child = this.on_eiland_today_list[child_index];
            if (this.child.id == child_id) {
               this.status = "Kind Gegevens Gereed"
                return; // focus is on cild
            }
        }
        for (var child_index in this.list) {
            this.child = this.list[child_index];
            if (this.child.id == child_id) {
               this.status = "Kind Gegevens Gereed"
                return;  // focus is already on child
            }
        }
        this.loadChild(child_id)
    }

    _loadChildSucces(result) {
         result.child_full_name = result.first_name+" "+result.last_name; // Gebeurd niet op server?
         this.list.push(result);
         this.child = result;
         this.status = "Kind Gegevens Gereed"
    }

    loadChild(child_id) {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/"+child_id+"/",
        }
        Auth.authorize_request_data(request_data);
        this.status = "Kind Gegevens Worden Gehaald"
        return m.request(request_data)
                .then(this._loadChildSucces)
                .catch((e) => {
                     console.log("Child loadChild Error: "+e );
                });

    }

}

module.exports = new Child()
