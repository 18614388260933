var m = require("mithril")
var AppState = require("../services/AppState")
// var EvGService = require("../services/EvGService")
var ChildEilandday = require("../models/ChildEilandday")
// import {DaytypeDropdown, ChildRelatedDaytypeDropdown } from "../components/DaytypeDropdown";
var DaytypeDropdown = require( "../components/DaytypeDropdown");

var Breadcrumb = require("../components/Breadcrumb")

class ChildEilanddayActivityListItem {
    constructor(vnode) {
       this.chileilandday = vnode.attrs.childeilandday;
    }

	view(vnode) {
        var item_data = []
        var item_data_1 = [] // eerste regel van item
        var item_data_2 = [] // tweede regel van item

        var class_name="child-data"

        item_data_1.push( m('.pull-left', {style:"display:inline-block"}, this.childeilandday.child_full_name));
        //item_data_1.push( m('.pull-left', {style:"display:table; margin:0 auto;"},
        //                       this.childeilandday.school.data));

        //item_data_1.push( m('.pull-right', {style:"display:inline-block;"},
        //                  this.childeilandday.delivery_time_short+'-'+this.childeilandday.pickup_time_short));

        item_data_2.push( m('.activity', this.childeilandday.day_type_name));
        class_name="child-activity-data";

        item_data.push(m('.pull-left', { style:"width: 98vw"}, item_data_1));
        item_data.push(m('.pull-left', { style:"width: 98vw"}, item_data_2));

		return m('li.evg-list-item.media', [
			m('a', {//config: m.route,
				    href: '#!/child/' + this.childeilandday.child + "/eilandday/"+this.childeilandday.id
			      },
			  [ m('img.media-object.small.pull-left', {no_src: '/api/child-pics/'+this.childeilandday.child }),
                m('span.list-item-data '+class_name, item_data),
			  ])
		    ])
	}

}


class ChildEilanddayActivityList {
    constructor(vnode) {
       // this.chileilandday = vnode.attrs.childeilandday;
    }

	view(vnode) {
        if (ChildEilandday.childeiland_today_count == 0) {
            return m('span.evg_message', "Geen Kinderen vandaag");
        }
		let displayed_items = [];
		let even_odd = '';
		let items = ChildEilandday.childeiland_today_list.map(function(childeilandday) {
		    // item tonen als 'alles tonen' is geselecteerd,
		    // of als het dagtype gelijk is aan het geselecteerde dagtype
		    if (childeilandday.school) {
		        if(AppState.schoolFilter != 0) {
		           if(childeilandday.school.id == AppState.schoolFilter) {
         			   displayed_items.push( m(ChildEilanddayActivityListItem,
      			                               { childeilandday: childeilandday,}
			                           ));
		           }
		        } else {
      			   displayed_items.push( m(ChildEilanddayActivityListItem,
    			                           { childeilandday: childeilandday,}
			                           ));
			    }
			}
		});
		if (displayed_items.length == 0) {
            return m('span', {class:"evg_message"}, "Geen Kinderen");
		}
		return m('ul.evg_list.list_level_1', displayed_items);
	}
}


class ChildActivityList {

    constructor(vnode) {
       const crumb = {url:"/childactivitylist",
                      text:"kind/Activiteit"};
       Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {
              m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                  ]);

              return m("childactivity.evg_module",
                       [m(DaytypeDropdown),
                        m("div", {style:"overflow-y: scroll; height: 79vh;"},
                           m(ChildEilanddayActivityList)),
                       ]
                      )
    }
}

module.exports = ChildActivityList
