var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")


class UrgencyDropDown {

  view(vnode) {
    return m('select', //.evg_block',
             {id:"id_urgency_select", name:"urgency",
              onchange: function(ev) {AppState.setUrgencyFilter(ev.target.value) }},
              EvGService.urgency_option_list.map((urgency) => {
                   if (urgency.value==AppState.urgencyFilter) {
                       return m('option', { value: urgency.value, selected:"selected" }, urgency.name)
                   }

                   return m('option', { value: urgency.value }, urgency.name)
              })
             )
  }
}


module.exports = UrgencyDropDown
