var m = require("mithril")
var EvGService = require("../services/EvGService")
var Breadcrumb = require("../components/Breadcrumb")
var AppState = require("../services/AppState");
var OptionValueMap = require("../models/OptionValueMap")
var Settings = require("../models/Settings");

var CrewEilandday = require("../models/CrewEilandday")
var ChildEilandday = require("../models/ChildEilandday")
var ChildImgNameSmall = require("../components/ChildImgNameSmall")
var ChildOrderDropdown = require( "../components/ChildOrderDropdown");

var TimeAsBar = require('../components/TimeAsBar')
var TimeAsBarLabels = require('../components/TimeAsBarLabels')

class ChildEilanddayTime {
    constructor(vnode) {
    }

	view(vnode) {
        const _childeilandday = vnode.attrs.childeilandday;
        let _timeItem = [_childeilandday.day_start_time, _childeilandday.day_end_time];
        let _timeList = [_timeItem,];
        return m('.time',
                 m(TimeAsBar,
                   {StartTime: _childeilandday.day_start_time, EndTime: _childeilandday.day_end_time,
                    TimeList: _timeList}))
    }
}


class ChildListItem {

    constructor(vnode) {
    }

	view(vnode) {
        const _childeilandday = vnode.attrs.childeilandday;
        let item_data = []
        let item_data_0 = []
        let item_data_1 = []
        let item_data_2 = []

        let class_name=".child-data";
        let class_media_obj_size = ".small";

        const child_item_route =  "#!/child/"+_childeilandday.child +
                                  "/eilandday/"+_childeilandday.id;
		return m('.evg-flow-list-item.media',
		          [ m('a',
		              { href: child_item_route },
                      [ m(ChildImgNameSmall, { child: _childeilandday.child_full,
                                               withImg: false, withLastName: false}),
                        m(ChildEilanddayTime, {childeilandday: _childeilandday})
                      ])
		          ])
	}

}

/* naar components */

class CrewEilanddayTime {

    constructor(vnode) {
        this.time_list = [];
        this.addStartEndTime = this.addStartEndTime.bind(this);
        this.time_as_bar = new TimeAsBar()
    }

    addStartEndTime(creweilandday){
        let _timeItem = [creweilandday.from_time_short, creweilandday.to_time_short];
        this.time_list.push(_timeItem);
        // console.log("# creweilandagen: "+this.time_list.length);
        // onder veervangen met boven
        this.time_as_bar.addStartEndTime(creweilandday.from_time_short, creweilandday.to_time_short);

    }

	view(vnode) {
	    // console.log("# timeslots: "+this.time_list.length);
        const _creweilandday = vnode.attrs.creweilandday;
        return m('.time',
                  // m(this.time_as_bar)
                  m(TimeAsBar,
                    {// StartTime: _creweilandday.from_time_short, EndTime: _creweilandday.to_time_short,
                     TimeList: this.time_list
                     }))
    }
}

class CrewListItem {

    constructor(vnode) {
      this.creweilanddaytime = vnode.attrs._CrewEilanddayTime
    }

	view(vnode) {
        const _creweilandday = vnode.attrs.creweilandday;
        const _time_full = _creweilandday.from_time_short+'-'+_creweilandday.to_time_short;
        const crew_item_route =  '#!/crew/'+_creweilandday.crew +
                                  "/eilandday/"+_creweilandday.day;

        let item_data = [
		          [ m('a',
		              { href: crew_item_route },
                       [m('.crew-img-name-small.full-name', m('.first-name',_creweilandday.first_name )),
                        m(this.creweilanddaytime)
                       ]
                      )
		          ]
        ]
		return m('.evg-flow-list-item',
		         item_data)
	}
}



class EvGToday {

    constructor(vnode) {
        // console.info("EvGToday.constructor");
        const today_crumb = {url: '/evgtoday', text: "Ons Eiland"};
        Breadcrumb.addCrumb(today_crumb);

        Settings.setSettingsSubList(['child_order' ]); //, 'daytype_filter']); // weer weghalen in onremove

        this.header_data = [m(Breadcrumb),
                            m("span.filter-date", AppState.get_filter_date())]

        // we gebruiken dit 2 keer op deze pagina, daarom zo even hier
        //this.filter_url_element = //m('.',
        //                            m('a.button_choice',
		//                              { href: "#!/evgtoday-filter" },
        //                              "Filt/Sort")
        //                              //)


        this.renderAllCrew = this.renderAllCrew.bind(this);
        this.renderAllChildren = this.renderAllChildren.bind(this);
    }

    oninit(vnode) {
        console.info("EvGToday.oninit");
    }

    renderSettings() {
        return m('a.button_choice',
		         { href: "#!/evg-settings" },
                 "Filt/Sort")
    }

    //renderFilterSettings() {
    //    return m('a.button_choice',
    //               { href: "#!/evgtoday-filter" },
    //                 "Filt/Sort")
    //}

    renderAllCrew(vnode) {
        let test_list = new Map();
        CrewEilandday.creweiland_today_list.forEach((creweilandday) => {
             let cli = null;
             if(test_list.has(creweilandday.crew)) {
                 cli = test_list.get(creweilandday.crew);
                 cli.addStartEndTime(creweilandday);
             } else {
                 cli = new CrewEilanddayTime();
                 cli.creweilandday=creweilandday; // todo: tijdelijk even bewaren
                 cli.addStartEndTime(creweilandday);
             }
             test_list.set(creweilandday.crew, cli);

        });
        let item_list = Array.from(test_list).map(([key,cli]) => {
            return m(CrewListItem, { creweilandday: cli.creweilandday,
                                     _CrewEilanddayTime: cli});
        });

        if(item_list.length == 0) {
 		    return m('.evg_message', "Geen Crewlingen gevonden");
        } else {
           item_list.push( m('.evg_message', "Einde lijst"))
        }
		return m('.evg-list',
		         [m('.evg-flow-list-item',
		            [this.renderSettings(),
		             m('.time-labels', m(TimeAsBarLabels, {StartTime: 7, EndTime:18}))
		             ]),
		          item_list]);
    }

    renderAllChildren(vnode) {
        let item_list = []; // de lijst
        let item_id_list = []; // bijhouden wie al in de lijst staat

        let date = 0;
        // let date_data;
        // console.log("Settings.getValue('child_order'): "+Settings.getValue('child_order'));
        // console.log("Settings.getValue('foo'): "+Settings.getValue('foo'));
        // eerste aankomst vooraan
        // console.log("AppState.getChildOrder(): "+AppState.getChildOrder());
        let sortedChildEilanddayList = [];
        if(Settings.getValue('child_order')==0 ){ // == OptionValueMap.resolve('option_start_time')) {
           sortedChildEilanddayList =
           ChildEilandday.childeiland_today_list.sort((a,b)=>{if(a.day_start_time<b.day_start_time){return -1} return 1})
        }
        if(Settings.getValue('child_order')==1) { // ==OptionValueMap.resolve('option_first_name')) {
           sortedChildEilanddayList =
           ChildEilandday.childeiland_today_list.sort((a,b)=>{if(a.child_full.first_name<b.child_full.first_name){return -1} return 1})
        }
        if(Settings.getValue('child_order')==2 ){ // == OptionValueMap.resolve('option_age')) {
           sortedChildEilanddayList =
           ChildEilandday.childeiland_today_list.sort((a,b)=>{if(a.child_full.date_of_birth<b.child_full.date_of_birth){return -1} return 1})
        }
        sortedChildEilanddayList.map((childeilandday) => {
             if(!item_id_list.includes(childeilandday.child)){
                 item_list.push(m(ChildListItem, {childeilandday}));
                 item_id_list.push(childeilandday.child); //save child_id
             }
        });

        if(item_list.length == 0) {
 		    return m('.evg_message', "Geen Kind gevonden");
        } else {
           item_list.push( m('.evg_message', "Einde lijst"))
        }
		return m('.evg-list',
		         [m('.evg-flow-list-item',
		            m('.time-labels',
		              m(TimeAsBarLabels))), // ,{StartTime: 7, EndTime:18}))),
		          item_list]);
    }


    view(vnode) {
        //console.info("EvGToday.view");
        m.render(document.getElementById("header"), this.header_data);

        return m( "splash.evg_module.scrollable",
                 [ m(".evg_block", this.renderAllCrew()),
                   m(".evg_block", this.renderAllChildren()),
                 ])
    }

    onremove(vnode) {
        // console.log("On Remove");
        // Settings.setSettingsSubList([]); // als we naar SettingsPAge gaan, wordt dit aangeroepen.
    }
}

module.exports = EvGToday
