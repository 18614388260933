var m = require("mithril")
var AppState = require("../services/AppState")
var Auth = require("../services/Auth");
var ChildEilandday = require("../models/ChildEilandday")


class ChildPresence {

    constructor(vnode) {
        this.tapCount = 2; // voor wat meer controle over per ongeluk klikken
        this.updateIsPresent = this.updateIsPresent.bind(this);
    }

    updateIsPresent(event) {
       event.preventDefault();
       this.tapCount--;
       if(this.tapCount==0) {
           ChildEilandday.createChildEilanddayPresence(event.target.attributes.value.value);
       }
    }

	view(vnode) {
        const _childeilandday = vnode.attrs.childeilandday;
	    // Alleen Tonen Als Groep filter aanstaat, en het kind aanwezigheidsplicht heeft
        if (AppState.daytypeFilter > 0 && _childeilandday?.child_full.on_eiland_school ) {
           if(_childeilandday.is_present) {
               return m('.pull-left.list-item-action.present_check.present_green',
                        {style: "font-size: smaller;"},
                         'Aanwezig')
           } else {
               let tapCountMsg = "";
               if (this.tapCount > 0) {
                  tapCountMsg = " ("+this.tapCount+")";
               }
               if(this.tapCount==1 ) {
               return m('.pull-left.list-item-action.present_check.not_present_1', // geel
                         {style: "font-size: smaller;",
                          value: _childeilandday.id,
                          onclick: this.updateIsPresent},
                         'Niet Aanwezig'+tapCountMsg)
               }
               return m('.pull-left.list-item-action.present_check.not_present_2', // rood
                         {style: "font-size: smaller;",
                          value: _childeilandday.id,
                          onclick: this.updateIsPresent},
                         'Niet Aanwezig'+tapCountMsg)
           }
        }
        // niet tonen
        return null
    }
}


class ChildPresenceWrapper {
    // De wrapper zit om 'het blok' dat aangeeft of een kind er is of niet.
    // Zolang een kind niet als 'aanwezig' is bestempeld, worden de 'taps' erop geteld.
    // Bij 2 tqps wordt kind op 'aanwezig' gezet
    constructor(vnode) {
        this.tapCount = 2; // voor wat meer controle over per ongeluk klikken
        this.updateIsPresent = this.updateIsPresent.bind(this);
        this.childeilandday_id = vnode.attrs.childeilandday.id;
    }

    updateIsPresent(event) {
       event.preventDefault();
       this.tapCount--;
       if(this.tapCount==0) {
           ChildEilandday.createChildEilanddayPresence(this.childeilandday_id ); //event.target.attributes.value.value);
       }
    }

	view(vnode) {
        const _childeilandday = vnode.attrs.childeilandday;
       if(_childeilandday.is_present) {
           return m('.pull-left.list-item-action',
                    [vnode.attrs.content,
                     m('.present_green', {style: "font-size: smaller;"},
                       'Aanwezig')])
       }
       let tapCountMsg = "";
           if (this.tapCount > 0) {
              tapCountMsg = " ("+this.tapCount+")";
           }
           return m('.pull-left.list-item-action',
                     {style: "font-size: smaller;",
                      value: _childeilandday.id,
                      onclick: this.updateIsPresent},
                     [vnode.attrs.content,
                      m('.not_present_'+this.tapCount, {style: "font-size: smaller;"}, 'Niet Aanwezig'+tapCountMsg)])

    }
}


class ChildImg {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child
     let class_media_obj_size = ".small";
     return ( m('.child-img',
                m('img.media-object.pull-left'+class_media_obj_size, { src: _child.mugshot_url }))
             )
   }
}


class ChildName {
   constructor(vnode) {
       // this.child = vnode.attrs.child
   }

   view(vnode) {
     const _child = vnode.attrs.child
     return ( m('.pull-left.full-name',
                [ m('.first-name-big', _child.first_name),
                  m('.last-name', _child.last_name) ] ))
   }
}


class ChildImgName {
   constructor(vnode) {
   }

   view(vnode) {
     const _child = vnode.attrs.child
     const _with_image = vnode.attrs.withImg ? vnode.attrs.withImg : false;
     const _childeilandday = vnode.attrs.childeilandday ? vnode.attrs.childeilandday : null;

     const class_media_obj_size = ".small";
     // const _child_mugshot_url = Auth.getDomain()+_child.mugshot_url;
     let mugshot_url = _child.mugshot_url;
     if( mugshot_url.startsWith("//www.gravatar.com/")) {
        mugshot_url = "https:"+mugshot_url;
     }
     if (!mugshot_url.startsWith("http")) {
        mugshot_url = Auth.getDomain()+mugshot_url;
     }
     let _content = m('.child-img-name.pull-left',
                      [ m('.w2c-1.pull-left',
                        [m('img.media-object.pull-left'+class_media_obj_size, { src: mugshot_url }),
                         m(ChildName, {child: _child,})]),
                      ])
     // Alleen Wrappen als Groep filter aanstaat, en het kind aanwezigheidsplicht heeft
     if (AppState.daytypeFilter > 0 && _childeilandday?.child_full.on_eiland_school ) {
         return m(ChildPresenceWrapper,
                     { childeilandday: _childeilandday,
                      content: _content})
     }
     return _content
   }
}


module.exports = ChildImgName
