var m = require("mithril")

var Auth = require("../services/Auth")
var AppState = require("../services/AppState")

class CaretakerChangeRequest  {

    constructor() {
      this.count = 0;
      this.list = [];
      this.status_msg = 'Ophalen Data'
      this.loadChangeRequestList = this.loadChangeRequestList.bind(this);
      this._receiveChangeRequestList = this._receiveChangeRequestList.bind(this);
    }

    _receiveChangeRequestList(result){
        this.list = result.results;
        this.count = result.count;
        this.status_msg = "Data Geladen"
    }

    loadChangeRequestList() {
        this.status_msg = "Ophalen Data"
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/caretaker-changerequest/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveChangeRequestList);
    }

    _receiveChangeRequestDelete(result){
        //this.list = result.results;
        //this.count = result.count;
        this.status_msg = "Data Geladen"
    }

   closeChangeRequest(changerequest_id) {
        let request_data = {
            method: "DELETE",
            url: Auth.getDomain()+"/api/caretaker-changerequest/"+changerequest_id,
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveChangeRequestDelete);
   }

}

module.exports = new CaretakerChangeRequest()
