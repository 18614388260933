var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
/* Display Activities and planned activities for given child on given day */
var ChildActivityResource = require("../models/ChildActivityResource");

class ChildEilanddaySchedule {

  view(vnode) {
    const schedule_list = vnode.attrs.schedule_list;
    let schedule_content = []
    schedule_list.map((childeilandday) => {
        const _content = childeilandday.day+" "+childeilandday.delivery_time+"-"+childeilandday.pickup_time;
        schedule_content.push(m('li.evg_activity', _content))
        })

    if (schedule_content.length == 0) {
        schedule_content.push( m('li.evg_activity', "Geen Rooster Data"));
	}
    return m('ul.evg_listlist_level_2',  schedule_content )
  }

}

module.exports = ChildEilanddaySchedule
