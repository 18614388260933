var m = require("mithril")
var DatePicker = require('mithril-datepicker')
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")
var UrgencyDropdown = require("../components/UrgencyDropdown")
var Child = require("../models/Child")
var ChildNote = require("../models/ChildNote")


const placeholder_text =
`Notitie Text.
Alleen bedoeld voor communicatie naar Crew van het Eiland.
NIET voor communicatie naar ouders. Gebruik daarvoor 'Blogbericht maken' via de Kind pagina`


class ChildNoteCreate  {
    constructor(vnode) {
        if (vnode.attrs.Id) {
          this.child_id = vnode.attrs.Id;
          Child.getChild(this.child_id);
          const crumb = {url: '/child/' + vnode.attrs.Id+'/notewrite',
                         text: "Notitie maken"}
          Breadcrumb.addCrumb(crumb);
        }
        this.date_from_picker = EvGService.format_date_to_datum(AppState.get_filter_date());
        this.date_to_picker = EvGService.format_date_to_datum(AppState.get_filter_date());

        this.view = this.view.bind(this);
        this.write_note = this.write_note.bind(this);
        this.check_dates = this.check_dates.bind(this);
    }

    check_dates() {
         // cant get autofill date-to to match date-from
         // as-a-hack: disable the SEND button
         let send_button = document.getElementById("send_note");
         if(this.date_to_picker<this.date_from_picker) {
            send_button.disabled = true;
         } else {
            send_button.disabled = false;
         }
    }

    on_text_enter(event) {
      // console.log("// hide all other elements "+event);
      event.target.scrollIntoView();

      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          if(item.id==event.target.id) {
              item.rows = "10"; // geselecteerde textarea wat groter maken
          } else {
              item.style.display = 'none';
          }
      })

    }
    on_text_leave(event) {
      // console.log("// show all elements "+event);

      let hide_elements = Array.from(document.getElementsByClassName("hide_on_text_enter"));
      hide_elements.forEach(function(item, index){
          item.style.display = '';
          item.rows = "5";  // geselecteerde textarea naar 3 regels
      })

    }

    write_note(e) {
        // console.log("write_note event: "+e);
        e.preventDefault();
        document.getElementById("send_note").disabled = true;

        var formData = new FormData(document.getElementById("id_child_note"));
        ChildNote.writeChildNote(formData);

        let parentCrumb = Breadcrumb.getParent();
        m.route.set(parentCrumb.url);
    }

    view(vnode) {
        var header_content = [
            m(Breadcrumb),
            m("span.filter-date", EvGService.filter_date),
        ]
        let blog_child_select = null;
        let child = null;
        let child_full_name = null;
        if (Child.child) {
            // notitie  invoeren voor kind vanaf kind lijst
            child = Child.child;
            child_full_name = child.first_name+" "+child.last_name
            // terug gaat naar kind pagina
            // enige kind in de lijst
            blog_child_select = m("select",
                                  { id:"id_child", name:"child", required:"required", autofocus:"autofocus"},
                                   [ m("option", {value:child.id}, child.first_name+" "+child.last_name),
                                   ]
            )

        }
        else {
           // een optie invoeren voor een van de kinderen.
           // (kind keuze is nog niet gemaakt)
           blog_child_select = m("select",
                                 { id:"child", required:"required", autofocus:"autofocus"},
                                 [ m("option", "Maak een Keuze"),
                                   Child.on_eiland_today_list.map(function(child){
                                                                       return m("option",
                                                                                {value:child.id},
                                                                                child.first_name+" "+child.last_name)})
                                 ]
           )
        }

        let from_picker = m(DatePicker,
                         { date: this.date_from_picker,
                           onchange: (chosenDate) => {
                                     this.date_from_picker = EvGService.format_date_to_datum(chosenDate);
                                     this.check_dates();
                              }
                         });
        let to_picker =  m(DatePicker,
                         { date: this.date_to_picker,
                           onchange: (chosenDate) => {
                                     this.date_to_picker = EvGService.format_date_to_datum(chosenDate);
                                     this.check_dates();
                              }
                         });


        let childnote_form = [
                 m("form", {id:"id_child_note", onsubmit:this.write_note } ,
                   [
                    m('input', {type:"hidden", name:"created_by", value:"1"}), // todo: fix dit: Wordt in de API gezet op de ingelogde gebruiker. Id moet wel bestaan
                    m("textarea.evg_block.hide_on_text_enter",
                      {rows:"6", cols:"40", id:"id_content", name:"content",
                       required:"required",
                       onfocusin:this.on_text_enter, onfocusout:this.on_text_leave,
                       placeholder:placeholder_text }),

                    m("div.evg_block.hide_on_text_enter",
                          [m("label", "Kind"),
                           m(".input", blog_child_select)
                           ]),

                    m("div.evg_block.hide_on_text_enter",
                           [m("label", "Urgentie"),
                            m(".input", m(UrgencyDropdown))
                            ]),

                    m( "div.evg_block.hide_on_text_enter",
                        [ m("label", "Van"),
                          m('input', {id:"id_date_from", type:"hidden", name:"date", value:this.date_from_picker }),
                          m('.input', from_picker)
                        ]
                      ),
                    m( "div.evg_block.hide_on_text_enter",
                        [ m("label", "T/m"),
                          m('input', {id:"id_date_to", type:"hidden", name:"date_to", value:this.date_to_picker }),
                          m('.input', to_picker)
                        ]),

                    m("button.evg_action.hide_on_text_enter",
                      {id:"send_note", type:"submit"},
                      "Notitie voor Kind aanmaken"),
                   ])

        ];

        m.render(document.getElementById("header"),
                 header_content)
        return m( "childnote.evg_module", childnote_form )
    }
}


module.exports = ChildNoteCreate
