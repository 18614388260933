var m = require("mithril")
var Auth = require("../services/Auth");
var Child = require("../models/Child");
var EilandNote = require("./EilandNote")


/**
 * get/(or create) ChildNotes for a given Child
 *
 */
class ChildNote extends EilandNote {

    constructor(vnode) {
        super();
        this.list = [];
        this.count = 0;
        this.marked_seen_count = "-";
        this.marked_unseen_count = "-";
        this._receiveList = this._receiveList.bind(this);
        this.loadList = this.loadList.bind(this);

        this.child_list = [];
        this.child_count = 0;
        this.child_note_marked_unseen_count = "-";
        this._receiveListForChild = this._receiveListForChild.bind(this);
        this.loadListForChild = this.loadListForChild.bind(this);
        this.getListForChild = this.getListForChild.bind(this);

        this.writeChildNote = this.writeChildNote.bind(this);
        this._recieveWriteChildNote = this._recieveWriteChildNote.bind(this);

        // this.loadList();
    }

    _receiveList(result) {
        this.list = result;
        this.count = result.length;
        this.marked_unseen_count = 0;
        this.list.forEach((note) => {if(!note.marked_as_seen){this.marked_unseen_count++}});
        // console.log("this.marked_unseen_count: "+this.marked_unseen_count);
    }

    loadList() {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/childnote/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveList);
    }

    _receiveListForChild(result) {
        this.child_list = result.results;
        this.child_count = result.count;
    }

    loadListForChild(child_id) {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child/"+child_id+"/note/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveListForChild);
    }

    getListForChild(child_id) {
         // console.log("getListForChild("+child_id+")");
         this.child_list = this.list.filter( (note) => { return note.child_id==child_id } );
         this.child_note_marked_unseen_count = 0;
         this.child_list.forEach( (note) => {if(!note.marked_as_seen){this.child_note_marked_unseen_count++}} );
         return this.child_list
    }

    _recieveWriteChildNote(result) {
        // console.log("receiveWriteChildNote: "+result)
        this.loadListForChild(Child.child.id); // herladen notities voor kind
        // this.loadList();
    }

    writeChildNote(formData) {
        //console.log("ChildNote writeChildNote: "+formData);
        let request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/child/"+Child.child.id+"/note/create/",
            body: formData,
            extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this._recieveWriteChildNote)
         .then(this.loadList())
         .then(this.getListForChild(Child.child.id))
         .catch((e) => {
                 console.error("ChildNoteCreate Error: "+e);
                })
    }

}

module.exports = new ChildNote()
