var m = require('mithril')
var _ = require('lodash')


class Breadcrumb {

    constructor(vnode) {
      this.crumbs = [];

      // this.view = this.view.bind(this);

      this.setCrumbs = this.setCrumbs.bind(this);
      this.addCrumb = this.addCrumb.bind(this);
      this.getParent = this.getParent.bind(this);
    }

    /* zet een vers spoor van kruimels */
    setCrumbs(crumbs) {
        this.crumbs = crumbs;
        m.redraw();
    }


    /**
     * Voeg de gegeven crumb toe achter aan de lijst.
     * Als de Crumb al in de lijst zit, wordt dat geintepreteerd als: we gaan omhoog in de lijst.
     * De Crumbs worden dan verkort tot en met het niveau van de gevonden Crumb
     */
    addCrumb(crumb) {
         let new_Breadcrumb_crumbs = []
         for (var i = 0; i < this.crumbs.length; i++) {
             let old_crumb = this.crumbs[i];
             new_Breadcrumb_crumbs.push(old_crumb);
             if (JSON.stringify(old_crumb) === JSON.stringify(crumb)) {
                 this.crumbs = new_Breadcrumb_crumbs;
                 return;
             }
         }
         this.crumbs.push(crumb);
    }


    getParent() {
       if(this.crumbs.length>1) {
           return this.crumbs[this.crumbs.length-2]
       }
       return this.crumbs[0]
    }


   view(vnode) {
        // Dit is een soort van achtervang, om af te dwingen dat of "Menu" of "Login" als eerste crumb wordt gebruikt.
        // Todo: volgens mio moet alleen gecontroleerd worden op "Menu". (het heeft van doen met herkijk op mobiel)
        if(vnode.state.crumbs[0] &&
           (vnode.state.crumbs[0].url != '/menu') &&
           (vnode.state.crumbs[0].url != '/login')) {
               m.route.set("/login");
        }

        let map_function = function(b,i) {
                        return m('.breadcrumb'
                                 , [ m('a.breadcrumb-link',
                                     { href: "#!"+b.url,
                                       config: m.route},
                                     [ b.icon ? m('i.material-icons', b.icon) : '',
                                       b.text ? m('p', b.text) : '' ])
                                 , i < vnode.state.crumbs.length - 1 ? m('i.material-icons', '>') : ''
                ])
        }
         return m('.breadcrumb', [_.map(this.crumbs, map_function)])//.bind(this))])

   }

}

module.exports = new Breadcrumb()
