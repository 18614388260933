var m = require("mithril")

class ActivityResourceListItem {

    constructor(vnode) {
    }

	view(vnode) {
	    const activityresource = vnode.attrs.activityresource
        let item_data = [];
        // Activiteietnaam en Crew Naam
        let item_data_1 = [];
        console.log("activityresource.id: "+activityresource.id);
        let activity_name = activityresource.activity_name ? activityresource.activity_name : 'Geen Activiteit';
        item_data_1.push(m('.pull-left', {style: 'width: 100%;'},
                           activity_name));
        if (vnode.attrs.withFullName) {
            let crew_full_name = activityresource.crew_full_name ?activityresource.crew_full_name : 'Geen Crewling'
            item_data_1.push(m('.pull-left', {style: 'xwidth: inherit;'}, crew_full_name))
        }

        // Groep Naam en Tijd
        let item_data_2 = [];
        const day_type_name = activityresource.day_type_name ? activityresource.day_type_name : 'Geen Groep';
        item_data_2.push( m('.pull-right', day_type_name)
                         );
        item_data_2.push( m('br')); // ondat ik het mt css niet voor elkaar krijg
	    const start_end_time = m('.start-end-time', activityresource.start_time_short+'-'+activityresource.end_time_short);
        item_data_2.push( m('.pull-right', start_end_time)
                          );

        item_data.push(m('.pull-left two-third-width', item_data_1));
        item_data.push(m('.pull-right one-third-width', item_data_2));

		return m('li.evg-list-item', {style: 'width: 100%;'},
		         m('a',
		           { // config: m.route,
				     href: '#!/activityresource/' + activityresource.id },
				   m('.pull-left', {style: 'width: 100%;'}, item_data)
				 ))
	}
}

module.exports = ActivityResourceListItem
