var m = require("mithril")
var EvGService = require("../services/EvGService");
var Auth = require("../services/Auth");
var AppState = require("../services/AppState");
var Breadcrumb = require("../components/Breadcrumb");
var ActivityResource = require("../models/ActivityResource")


class ActivityResourceListItem {
    constructor(vnode) {
    }

    view(vnode) {
     console.log("vnode.attrs.Id: "+vnode.attrs.Id);
     return
   }
}


class ChildOtherData {
    constructor(vnode) {
    }

    view(vnode){
       if( !ActivityResource.child_id ) {
          return m('.per-child-data',
                     m('p', "Klik Op Kind Voor Zien overige Gekaderde Activiteiten.",
                     m('p', 'Klik kind Nogmaals Voor WeerZien Alle Kinderen.')))
       }

       let _child_other_data = [];
       ActivityResource.child_activityresource_today.reduce((result, activityresource) => {
                    if(activityresource.id==ActivityResource.activityresource.id) {
                       return result
                    }
                    /*
                    result.push(m('li.pull-left.evg_activity.evg-list-item'
                                    ,m('.pull-left',
                                        m('.pull-left',activityresource.activity_name
                                                      ,activityresource.location_name)
                                           ,m('.pull-right.start_end_time',
                                                         activityresource.start_time_short+"-"+activityresource.end_time_short)
                                        )
                                    ,m(".pull-left", " "+activityresource.crew_full_name))
                                    ); */
                    result.push(m('li.pull-left.evg_activity.evg-list-item'
                                    ,m('.pull-left',
                                         m('.pull-left', activityresource.activity_name)
                                       ,m('br')
                                        ,m('.pull-left', {style:"width: 60vw"}
                                           ,m('.pull-left', activityresource.location_name)
                                           ,m('.pull-right.start_end_time',
                                                         activityresource.start_time_short+"-"+activityresource.end_time_short))

                                       ,m('br')
                                       ,m('.pull-left', activityresource.crew_full_name))

                                    ));
                    return result
                    }, _child_other_data );

       // console.log("child other data.length: "+_child_other_data.length);
       if(ActivityResource.child_id && _child_other_data.length==0) {
           _child_other_data.push(m('li.pull-left.evg_activity',
                                    "Geen Andere Gekaderde Activiteiten."));
       }
       return m('ul.evg_list.list_level_2', _child_other_data)

    }

}

class ActivityResourceList {
    constructor(vnode) {
       this.getChildAcitivtyresource.bind(this);
       // this._childFullName = null;
       this._child_id = null
    }

    getChildAcitivtyresource(event) {
         let child_id = event.target.getAttribute('data-child-id');
         if(ActivityResource.child_id==child_id) {
             // dit is tweede keer klikken op kind: resetten voor tonen alle kinderen
             ActivityResource.resetActivityResourceSameDateForChild();
         } else {
             // Gekaderde activiteiten vooor kind ophalen.
             ActivityResource.loadActivityResourceSameDateForChild(child_id);
         }
    }

    view(vnode) {
     let activityresource = ActivityResource.activityresource;
     let data = []
     // activiteit
     data.push(m('.label_value',
                   [m('label', 'Activiteit'),
                    m('.value', activityresource.activity_name)]))

     // dagsoort
     data.push(m('.label_value',
                   [m('label', 'Groep'),
                    m('.value', activityresource.day_type_name)]))
     // crew
     data.push(m('.label_value',
                   [m('label', 'Crewling'),
                    m('.value', activityresource.crew_full_name)]))
     // description
     data.push(m('.label_value',
                   [m('label', 'Omschrijving'),
                    m('.value', activityresource.description)]))
     // van-t/m
     data.push(m('.label_value',
                   [m('label', 'Van-Tot'),
                    m('.value', activityresource.start_time_short+"-"+activityresource.end_time_short)]))
     // # plekken
     // # gereserveerd
     // kinderen
     let child_list_rendered = []
     activityresource.child_list.map((child) => {
         // alle kinderen tonen als van geen kind de Activiteiten zijn opgehaald
         // anders alleen dat ene kind tonen
         if(!ActivityResource.child_id||ActivityResource.child_id==child.id) {
             child_list_rendered.push(m('a.button_choice',
                                        {style: "display: inline-block;",
                                         onclick: this.getChildAcitivtyresource,
                                         'data-child-id': child.id},
                                        child.child_full_name))
         }
      })
     if(child_list_rendered.length == 0) {
         child_list_rendered.push(m('span', "Geen kinderen"));
     }
     // console.log( " child_id: " + ActivityResource.child_id);
     data.push(m('.label_value',
                   [m('label', 'Kinderen'),
                    m('.value',
                        m('.child_list', child_list_rendered),
                        m(ChildOtherData))
                    ]))

     return m('div', {"id":vnode.attrs.Id}, data)
    }
}


class ActivityResourcePage {

    constructor(vnode) {
       ActivityResource.resetActivityResourceSameDateForChild(); // reset voor als er nog data stat van eerder kind
       if (vnode.attrs.Id) {
           try {
               this.activityresource = ActivityResource.getActivityResource(vnode.attrs.Id);
           } catch(err) {
               console.error("ActivityResourcePage.constructor: "+err);
           }

           const crumb = {url: '/activityresource/'+vnode.attrs.Id,
                          text: "Activiteit"}
           Breadcrumb.addCrumb(crumb);
       } else {
           console.error("ActivityResourcePage geen id")
       }
    }

    view(vnode) {
        m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date()),
                 ] );

        return m("activityresource-page.evg_module",
               [
                 m("div",{style:"overflow-y: scroll; height: 100vh; width:99vw;"}, // Todo: dit naar css-class
                   m(ActivityResourceList)
                 ),
               ]
        )
    }
}

module.exports = ActivityResourcePage
